import React from "react";

// Customizable Area Start
import {
  Button,
  Box,
  Typography,
  styled,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import TermsConditionsController, {
  Props
} from "./TermsConditionsController";

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (

      <CustomDialog open={this.state.open} onClose={this.handleClose} maxWidth="sm" fullWidth>

  {!(this.state.UPContent || this.state.BPContent) ? (
    <Loader loading={true} />
  ) : (
  
    <>
      <DialogTitle className="dialogTitleTypo">Privacy Policy</DialogTitle>
      <CustomDialogContent>
        
        {this.state.UPContent && (
          <Typography className="contentTextTypo">{this.state.UPContent}</Typography>
        )}
       
        {this.state.BPContent && (
          <Typography className="contentTextTypo">{this.state.BPContent}</Typography>
        )}
        <Box className="checkBox_Main_Box">
          <Box>
            <FormControlLabel
              sx={{ display: 'flex', marginRight: '0px' }}
              data-test-id="agreeToTerms"
              control={
                <StyledFieldCheckBox
                  as={Checkbox}
                  data-test-id="agreeToTermsInside"
                  name="agreeToTerms"
                  className="root"
                  disableRipple
                  color="default"
                  checkedIcon={<span className={`icon checkedIcon`} />}
                  icon={<span className="icon" />}
                  inputProps={{
                    "aria-label": "decorative checkbox",
                  }}
                  onChange={(event) => this.setRememberMePolicy(event.target.checked)}
                  checked={this.state.checkedRememberMePolicy}
                />
              }
              label={undefined}
            />
          </Box>
          <Box>
            <span className="text">I have read and agree to these Privacy Policy</span>
          </Box>
        </Box>
      </CustomDialogContent>
      <Box className="contentBox">
        <CustomCancelButton
          data-test-id="handleClose"
          variant="outlined"
          onClick={()=>{this.handleClose("policy")}}
        >
          Cancel
        </CustomCancelButton>
        <CustomAgreeButton
          data-test-id="handleAgreeTerms"
          variant="contained"
          onClick={this.handleAgreePolicy}
          disabled={!this.state.checkedRememberMePolicy}
        >
          Agree
        </CustomAgreeButton>
      </Box>
    </>
  )}
</CustomDialog>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    width: 'calc(68.95vw)',
    maxWidth: '90vw',
    minWidth: '300px',
    height:'80%',
    padding: theme.spacing(2), 
  },

 
  [theme.breakpoints.down('xs')]: {
    "& .MuiPaper-root": {
      width: '95vw', 
      
    },
  },

  [theme.breakpoints.up('sm')]: {
    "& .MuiPaper-root": {
      width: '80vw', 
      
    },
  },

  [theme.breakpoints.up('md')]: {
    "& .MuiPaper-root": {
      width: '70vw', 
      
    },
  },

  [theme.breakpoints.up('lg')]: {
    "& .MuiPaper-root": {
      width: '60vw', 
    },
  },
  [theme.breakpoints.up('xl')]: {
    "& .MuiPaper-root": {
      width: '50vw',
    },
  },
  
  "& .dialogTitleTypo": {
    color: "#000000",
    fontFamily: "Inter",
    fontSize: "48px",
    fontWeight: 700,
    lineHeight: "56px",
    letterSpacing: "-0.015em",
    textAlign: "center",
    marginTop: "40px",
    [theme.breakpoints.down("xl")]: {
      fontSize: "38px",
      marginTop: "6px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "36px",
      marginTop: "6px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "28px",
      marginTop: "-7px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      marginTop: "-7px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",
      marginTop: "10px",
    },
  },
  "& .contentTextTypo": {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "30px",
    fontWeight: 400,
    lineHeight: "40px",
    marginBottom: "16px",
    [theme.breakpoints.down("xl")]: {fontSize: "24px",
      lineHeight: "35px",marginBottom: "14px",},
    [theme.breakpoints.down("lg")]: {fontSize: "22px",
      lineHeight: "35px",marginBottom: "14px",},
    [theme.breakpoints.down("md")]: {fontSize: "20px",
      lineHeight: "28px",marginBottom: "12px",},
    [theme.breakpoints.down("sm")]: {
      fontSize: "17px",lineHeight: "19px",
      marginBottom: "10px",},
    [theme.breakpoints.down("xs")]: {
      fontSize: "16px",lineHeight: "16px",
      marginBottom: "8px"},
    },
  "& .checkBox_Main_Box": {
    display: "flex",
    flexDirection: "row",
    gap: "0px",
    alignItems: "center",
    
  },
  "& .text": {
    color: "#1E293B",
    fontFamily: "Inter",
    fontSize: "20px",
    fontWeight: "400",
    lineHeight: "28px",
    textAlign: "left",
    [theme.breakpoints.down("lg")]: {
      fontSize: "18px",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
  },

  "& .contentBox":{
    display: 'flex',
    justifyContent: 'center',
    gap: '40px',
    marginBottom: '80px',
    marginTop:'5px',
    padding:'0px 30px 0px 30px',
    [theme.breakpoints.down("xl")]: {
      padding:'0px 70px 0px 70px',
      marginBottom: '20px',
    },
    [theme.breakpoints.down("lg")]: {
      padding:'0px 16px 0px 16px',
      marginBottom: '15px',
    },
    [theme.breakpoints.down("md")]: {
      padding:'0px 16px 0px 16px',
      marginBottom: '10px',
    },
    [theme.breakpoints.down("sm")]: {
      padding:'0px 16px 0px 16px',
      marginBottom: '5px',
    },
    [theme.breakpoints.down("xs")]: {
      padding:'0px 16px 0px 16px',
      marginBottom: '0px',
    },
  }
}));

const CustomDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "24px 32px !important",
  [theme.breakpoints.down("md")]: {
    padding: "16px 24px !important",
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0px 16px !important",
  },
}));

const StyledFieldCheckBox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  "& .icon": {
    marginLeft: "1px",
    marginTop: "-6px",
    border: "1px solid #64748B",
    borderRadius: 6,
    width: 20,
    height: 20,
    [theme.breakpoints.down("xl")]: {
      width: 20,
      height: 20,
      marginLeft: "1px",
      marginTop: "-6px",
    },
    [theme.breakpoints.down("lg")]: {
      width: 19,
      height: 19,
      marginLeft: "1px",
      marginTop: "-6px",
    },
    [theme.breakpoints.down("md")]: {
      width: 18,
      height: 18,
      marginLeft: "1px",
      marginTop: "-6px",
    },
    [theme.breakpoints.between(552, 600)]: {
      width: 14,
      height: 14,
      marginLeft: "3px",
      marginTop: "-2px",
    },
    [theme.breakpoints.down(552)]: {
      width: 14,
      height: 14,
      marginLeft: "3px",
      marginTop: "-29px",
    },
   
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#FFFFFF",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "&.Mui-focusVisible": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  "& .checkedIcon": {
    backgroundColor: "#9E52F6",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 20,
      height: 20,
      [theme.breakpoints.down("xl")]: {
        width: 20,
        height: 20,
      },
      [theme.breakpoints.down("lg")]: {
        width: 19,
        height: 19,
       
      },
      [theme.breakpoints.down("md")]: {
        width: 18,
        height: 18,
       
      },
      [theme.breakpoints.down("sm")]: {
        width: 14,
        height: 14,
        
      },
      [theme.breakpoints.down("xs")]: {
        
        width: 14,
        height: 14,
       
      },
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: "#106ba3",
    },
  },
}));

const CustomCancelButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "56px",
  padding: "16px",
  gap: "40px",
  border: "1px solid #9E52F6",
  borderRadius: "8px",
  backgroundColor: "transparent",
  color: "#9E52F6",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    height: "48px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    height: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "14px",
    height: "36px",
  },
}));

const CustomAgreeButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "56px",
  padding: "16px",
  gap: "40px",
  border: "1px solid #9E52F6",
  borderRadius: "8px",
  background: "#9E52F6",
  color: "#FFFFFF",
  fontFamily: "Inter",
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "24px",
  textTransform: "capitalize",
  [theme.breakpoints.down("md")]: {
    fontSize: "18px",
    height: "48px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "16px",
    height: "40px",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "14px",
    height: "36px",
  },
}));
// Customizable Area End
