import React from "react";
// Customizable Area Start
import {
  Button,
  Grid,
  Box,
  Typography,
  ThemeProvider,
  CardContent,
  Card,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Stepper,
  Step,
  StepConnector,
  StepLabel,
  Chip,
  Divider,
  Snackbar,
  Alert,
  TextField,
  FormLabel,
  FormHelperText
} from "@mui/material";
import { createTheme, styled } from "@mui/material/styles";
import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import { visaIcon, mastercardIcon, activeStepIcon } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#9b59b6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#f3f4f6",
    },
  },
});

const getBackgroundColor = (planId: any) => {
  switch (planId) {
    case 1:
      return '#F6F0FF';
    case 2:
      return '#F0E5FF';
    case 3:
      return '#E1CCFF';
    default:
      return '#FFFFFF';
  }
};

const validationSchema = Yup.object({
  cardNumber: Yup.string()
    .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Card number must be 16 digits, grouped in 4s')
    .required('Card number is required'),
  cardHolderName: Yup.string().required('Cardholder name is required'),
  expiryDate: Yup.string()
    .matches(/^(0[1-9]|1[0-2])\/\d{4}$/, 'Expiry date must be in MM/YYYY format')
    .required('Expiry date is required'),
  cvv: Yup.string()
    .matches(/^\d{3,4}$/, 'CVV must be 3 or 4 digits')
    .required('CVV is required'),
});
function renderFormHelperText(error: any, touched: any, stateError: any) {
  const errorMessage = (error && touched && error) || stateError;

  if (errorMessage) {
    return (
      <FormHelperText
        style={{
          fontFamily: 'Inter',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',
          textAlign: 'left',
          color: "#DC2626",
          marginLeft: "0px",
          marginTop: '-5px'
        }}
      >
        {errorMessage}
      </FormHelperText>
    );
  }
  return null;
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  borderRadius: "8px",
  "& .MuiInputBase-root": {
    position: "relative",
    width: "100%",
    height: "56px",
    display: "flex",
    padding: "24px -1px",
    alignItems: "center",
    flexShrink: 0,
    background: theme.palette.common.white,
    marginTop: "-10px",
    borderRadius: "8px",
    color: '#94A3B8',
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px",
    color: '#475569'
  }
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: '10px',
  backgroundColor: '#9E52F6',
  color: '#FFFFFF',
  borderRadius: '8px',
  padding: '16px',
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "28px",
  fontFamily: "Inter",
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#7b1fa2',
  },
}));
// Customizable Area End

const configJSON = require("./config.js");

import CustomisableusersubscriptionsController, {
  Props,
} from "./CustomisableusersubscriptionsController";

export default class Customisablebrandusersubscriptions extends CustomisableusersubscriptionsController {

  static Customisablebrandusersubscriptions: Customisablebrandusersubscriptions;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: '#9E52F6',
    borderRadius: '56px',
    padding: '5.5px',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
    height: '41px',
    border: 0,
    width: '300px',
  }));

  StyledToggleButton = styled(ToggleButton)(({
    theme
  }) => ({
    textTransform: 'none', padding: '0 16px',borderRadius: '30px !important', borderLeft: '0',
    borderRight: '0', borderTop: '0',borderBottom: '0',
    "&:hover": {backgroundColor: 'transparent', color: '#0F172A',},
    '&.Mui-selected': {backgroundColor: '#FFFFFF', color: '#0F172A',width: 150, fontWeight: '700',
      fontSize: '18px', fontFamily: 'Inter',lineHeight: '26px',"&:hover": {backgroundColor: '#FFFFFF', color: '#0F172A',
      },},
    '&:not(.Mui-selected)': {color: '#FFFFFF', backgroundColor: '#9E52F',
      width: 150, fontSize: '20px',lineHeight: '28px', fontWeight: 400,},
    }));

  StyledMonthlyToggleButton = styled(this.StyledToggleButton)({
    textTransform: 'none', padding: '0 16px',borderRadius: '30px !important', borderLeft: '0',
    borderRight: '0', borderTop: '0',borderBottom: '0',
    "&:hover": {backgroundColor: 'transparent', color: '#0F172A',
    },
    '&.Mui-selected': {
      backgroundColor: '#FFFFFF', color: '#0F172A',width: 150, fontWeight: '700',
      fontSize: '18px',fontFamily: 'Inter', lineHeight: '26px',"&:hover": {backgroundColor: '#FFFFFF', color: '#0F172A',
      },
    },
    '&:not(.Mui-selected)': {color: '#FFFFFF', backgroundColor: '#9E52F',
      width: 150, fontSize: '20px',lineHeight: '28px', fontWeight: 400,},
   });

  // Customizable Area End


  render() {
    // Customizable Area Start
    const { activeStep } = this.state;
    const steps = ['Choose A Plan', 'Checkout', 'Subscribe Confirmation'];

    const stepsCompleted = ['Review The Plan', 'Payment Information', 'Subscribe Confirmation'];

    const getStepLabel = (index: any) => {
      return index < activeStep ? stepsCompleted[index] : steps[index];
    };
    return (
      <ThemeProvider theme={theme}>
        <MainWrapper>
          <Container maxWidth="xl" sx={{ py: 5 }}>
            <Box textAlign="center">
              <Typography className="pageTitle" mt={8}>
                Unlock Your Brand
              </Typography>
              <Typography className="secondTitle">
                Choose the plan that fits your needs.
              </Typography>
            </Box>



            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '56px' }}>
              <Box
                sx={{
                  width: '1054px',
                  border: '1px solid #CBD5E1',
                  borderRadius: '8px',
                  padding: '11px',
                }}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation={window.innerWidth < 600 ? 'vertical' : 'horizontal'}
                  connector={<CustomStepConnector />}
                >
                  {steps.map((label, index) => (
                    <Step key={label}>
                      <CustomStepLabel StepIconComponent={StepIcon}>{getStepLabel(index)}</CustomStepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </Box>




            {(this.state.subscriptionsPage && !this.state.checkoutPage && !this.state.confirmationPage) && <Box>
              <Box display="flex" justifyContent="center" mb={7} sx={{ marginTop: '50px' }}>
                <Box>
                  <this.StyledToggleButtonGroup
                    data-test-id="handleToggleChange"
                    value={this.state.plan}
                    exclusive
                    onChange={()=>this.handleToggleChange(this.state.plan)}
                    aria-label="plan selection"
                  >
                    <this.StyledToggleButton value="annual" aria-label="annual">
                      Annual
                    </this.StyledToggleButton>
                    <this.StyledMonthlyToggleButton value="monthly" aria-label="monthly">
                      Monthly
                    </this.StyledMonthlyToggleButton>
                  </this.StyledToggleButtonGroup>
                </Box>

              </Box>
              <Grid container spacing={4}>

                {this.state.userSubscriptions.map((plan: any, index: any) => (

                  <Grid item xs={12} sm={6} lg={3} xl={3} key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: 486,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: 2,
                        borderColor: plan.borderColor,
                        "&:hover": {
                          boxShadow: "0 0 0 2px #6200EA",
                        },
                      }}
                    >
                      <CardContent sx={{ flex: 1, textAlign: "center" }}>

                        <Box sx={{ display: 'flex' }}>
                          <Chip sx={{
                            color: '#1E293B',
                            padding: '8px 16px',
                            height: '40px',
                            backgroundColor: getBackgroundColor(plan.attributes.id),
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '24px',

                          }} label={plan.attributes.subscription_en_name} size="small" />
                        </Box>
                        <Typography
                          sx={{
                            marginTop: '16px',
                            color: '#1E293B',
                            fontFamily: 'Aeonik',
                            fontSize: '24px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {this.state.plan == "annual" && (
                            <>
                              <span>
                                QAR {plan.attributes.yearly_price}
                              </span>

                              <span style={{
                                color: '#1E293B',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                              }}>&nbsp; Per Year</span>
                            </>
                          )}

                          {this.state.plan == "monthly" && (
                            <>
                              <span>
                                QAR {plan.attributes.monthly_price}
                              </span>

                              <span style={{
                                color: '#1E293B',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                              }}>&nbsp; Per Month</span>
                            </>
                          )}

                        </Typography>


                        <Divider sx={{ marginTop: '24px' }} />
                        <Box sx={{ marginTop: '24px' }}>
                          {plan.attributes.feature.map((feature: any, idx: any) => (

                            <Typography
                              key={idx}
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                display: "flex",
                                textAlign: 'left',
                                mb: 1,
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21.78px',
                                gap: '8px'
                              }}
                            >
                              <span>
                                <img src={this.state.checkedIcon} />
                              </span>
                              {feature.feature_name_en}
                            </Typography>
                          ))}

                        </Box>
                      </CardContent>
                      <Box p={2}>
                        <Button
                          data-test-id="handleGetStarted"
                          onClick={() => { this.handleGetStarted(plan.attributes.id) }}
                          variant="outlined"
                          fullWidth
                          sx={{
                            textTransform: "none",
                            borderRadius: '8px',
                            height: '56px',
                            border: '1px solid #9E52F6',
                            color: "#9b59b6",
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            textAlign: 'left',
                            "&:hover": {
                              backgroundColor: "#9b59b6",
                              color: "#fff",
                            },
                          }}
                        >
                          Get Started
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} lg={3} key={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      height: 486,
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: 2,

                      "&:hover": {
                        boxShadow: "0 0 8px rgba(155, 89, 182, 0.5)",
                      },
                    }}
                  >
                    <CardContent sx={{ flex: 1, textAlign: "center" }}>

                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{
                          color: '#1E293B', padding: '8px 0px',
                          textAlign: 'left',
                          fontFamily: 'Inter',
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '24px',

                        }} >Customize Your Own Features</Typography>
                      </Box>



                      <Divider sx={{ marginTop: '24px' }} />
                      <Box sx={{ marginTop: '24px' }}>

                        <Typography

                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            textAlign: 'left',
                            mb: 1,
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '21.78px',
                            gap: '8px'

                          }}
                        >
                          Submit your request now and you'll get an offer from us as soon as possible.

                        </Typography>


                      </Box>
                    </CardContent>
                    <Box p={2}>
                      <Button
                        variant="outlined"
                        fullWidth
                        sx={{
                          textTransform: "none",
                          height: '56px',
                          borderColor: "#9b59b6",
                          borderRadius: '8px',
                          color: "#9b59b6",
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '24px',
                          textAlign: 'left',
                          "&:hover": {
                            backgroundColor: "#9b59b6",
                            color: "#fff",
                          },
                        }}
                      >
                        Submit Request
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>}
            {(this.state.checkoutPage && !this.state.subscriptionsPage && !this.state.confirmationPage) && <Box>
              <Box
                sx={{
                  p: 4,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  minHeight: '100vh',
                }}
              >
                <Snackbar
                  open={this.state.showToast}
                  autoHideDuration={6000}
                  data-test-id="handleCloseToast"
                  onClose={this.handleCloseToast}
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                  <Alert onClose={this.handleCloseToast} severity="error" sx={{ width: '100%' }}>
                    {this.state.toastMessage}
                  </Alert>
                </Snackbar>

                <Grid container spacing={4} maxWidth="lg">
                  <Grid item xs={12} md={7}>
                    <Card sx={{ borderRadius: '16px', boxShadow: 'none' }}>
                      <CardContent sx={{ marginTop: "-20px" }} >

                        <Formik
                          data-test-id="formik"
                          initialValues={{
                            cardNumber: '',
                            cardHolderName: '',
                            expiryDate: '',
                            cvv: '',
                          }}
                          validationSchema={validationSchema}
                          onSubmit={(values) => {
                            this.handlePaymentForm(values);
                          }}
                        >
                          {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
                            <Form noValidate autoComplete="off">
                              <StyledFormLabel htmlFor="cardNumber">Card Number</StyledFormLabel>
                              <Field
                                data-test-id="cardNumber"
                                as={StyledTextField}
                                fullWidth
                                name="cardNumber"
                                placeholder="0000 0000 0000 0000"
                                InputProps={{
                                  endAdornment: (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                      <Box>{visaIcon}</Box>
                                      <Box>{mastercardIcon}</Box>
                                    </Box>
                                  ),
                                }}
                                variant="outlined"
                                margin="normal"
                                value={values.cardNumber}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                  setFieldValue('cardNumber', this.formatCardNumber(e.target.value));
                                }}
                                onBlur={handleBlur}
                              />
                              {renderFormHelperText(errors.cardNumber, touched.cardNumber, null)}
                              <StyledFormLabel htmlFor="cardholderName">Cardholder Name</StyledFormLabel>
                              <Field
                                data-test-id="cardholderName"
                                as={StyledTextField}
                                fullWidth
                                name="cardHolderName"
                                placeholder="Cardholder name"
                                variant="outlined"
                                margin="normal"
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              {renderFormHelperText(errors.cardHolderName, touched.cardHolderName, null)}
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <StyledFormLabel htmlFor="expiryDate">Expiry Date</StyledFormLabel>
                                  <Field
                                    data-test-id="expiryDate"
                                    as={StyledTextField}
                                    fullWidth
                                    name="expiryDate"
                                    placeholder="MM / YYYY"
                                    variant="outlined"
                                    margin="normal"
                                    value={values.expiryDate}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      setFieldValue('expiryDate', this.formatExpiryDate(e.target.value));
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  {renderFormHelperText(errors.expiryDate, touched.expiryDate, null)}
                                </Grid>
                                <Grid item xs={6}>
                                  <StyledFormLabel htmlFor="cvv">CVV</StyledFormLabel>
                                  <Field
                                    data-test-id="cvv"
                                    as={StyledTextField}
                                    fullWidth
                                    name="cvv"
                                    placeholder="3 - 4 digits"
                                    variant="outlined"
                                    margin="normal"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    inputProps={{
                                      maxLength: 4,
                                      minLength: 3
                                    }}
                                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      e.target.value = e.target.value.replace(/\D/g, '');
                                    }}
                                  />
                                  {renderFormHelperText(errors.cvv, touched.cvv, null)}
                                </Grid>
                              </Grid>
                              <Box sx={{ mt: 2 }}>
                                <StyledButton data-test-id="form" type="submit" fullWidth>
                                  Next
                                </StyledButton>
                                <Typography data-test-id="handleNavigateBack" onClick={this.handleNavigateBack} sx={{
                                  marginTop: '20px', color: '#9E52F6', textAlign: 'center',
                                  fontFamily: 'Inter',
                                  fontSize: '20px',
                                  fontWeight: 700,
                                  lineHeight: '28px',
                                  cursor: 'pointerd'
                                }}>
                                  Back
                                </Typography>
                              </Box>
                            </Form>
                          )}
                        </Formik>
                      </CardContent>
                    </Card>
                  </Grid>


                  <Grid item xs={12} md={5}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: 490,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: 2,
                        border: '1px solid #CBD5E1',
                        "&:hover": {
                          boxShadow: "0 0 8px rgba(155, 89, 182, 0.5)",
                        },
                      }}
                    >
                      <CardContent sx={{ flex: 1, textAlign: "center" }}>

                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Box>
                            <Typography sx={{
                              color: '#1E293B',
                              textAlign: 'left',
                              fontFamily: 'Inter',
                              fontSize: '24px',
                              fontWeight: 700,
                              lineHeight: '32px',

                            }} >{this.state.userSelectedSubscriptions.attributes.subscription_en_name}</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{
                              marginTop: '14px',
                              color: '#1E293B',
                              fontFamily: 'Inter',
                              fontSize: '20px',
                              fontWeight: 400,
                              lineHeight: '28px',
                              textAlign: 'left'

                            }} >Billing cycle</Typography>
                          </Box>
                          <Box>
                            <Typography sx={{
                              marginTop: '5px',
                              color: '#1E293B',
                              fontFamily: 'Inter',
                              fontSize: '20px',
                              fontWeight: 700,
                              lineHeight: '28px',
                              textAlign: 'left'

                            }} >{this.state.plan=="monthly" ? "Monthly": "Yearly"}</Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ marginTop: '24px' }} />
                        <Box sx={{ marginTop: '24px' }}>
                          <Typography sx={{
                            color: '#1E293B',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            textAlign: 'left'

                          }} >Features</Typography>
                          
                          {this.state.userSelectedSubscriptions.attributes.feature.map((feature: any, idx: any) => (

                            <Typography
                              key={idx}
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                marginTop: '16px',
                                display: "flex",
                                textAlign: 'left',
                                mb: 1,
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21.78px',
                                gap: '8px'
                              }}
                            >
                              <span>
                                <img src={this.state.checkedIcon} />
                              </span>
                              {feature.feature_name_en}
                            </Typography>
                          ))}

                        </Box>
                        <Divider sx={{ marginTop: '24px', marginBottom: '-24px' }} />
                      </CardContent>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }} p={2}>
                        <Box sx={{
                          color: '#1E293B',
                          fontFamily: 'Inter',
                          fontSize: '18px',
                          fontWeight: 400,
                          lineHeight: '26px',
                        }}>Total</Box>
                        <Box sx={{
                          color: '#1E293B',
                          fontFamily: 'Inter',
                          fontSize: '18px',
                          fontWeight: 700,
                          lineHeight: '26px',
                        }}>{this.state.plan=="monthly"?this.state.userSelectedSubscriptions.attributes.monthly_price:this.state.userSelectedSubscriptions.attributes.yearly_price}</Box>


                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </Box>}
            {(this.state.confirmationPage && !this.state.subscriptionsPage && !this.state.checkoutPage) && <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#f9f9f9',
                  padding: 2,
                  textAlign: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mb: 4,
                  }}
                >

                  <Box><img src={this.state.successLogo}></img></Box>

                  <Typography variant="h5" sx={{
                    color: "#000000",
                    fontFamily: 'Inter',
                    fontSize: '30px',
                    fontWeight: 700,
                    lineHeight: '40px',
                    letterSpacing: '-0.005em',
                    textAlign: 'left',
                    marginTop: '36px'
                  }}>
                    Subscribed
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    paddingRight: '83px',
                    paddingLeft: '83px'

                  }}>
                    <Typography variant="body1" sx={{
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '28px',
                      textAlign: 'center',
                      color: '#1E293B',
                      marginTop: '24px',
                      [theme.breakpoints.down('xs')]: {
                        fontSize: '16px',

                      }
                    }}>
                      You have successfully subscribed to our memberships,<br /> please enjoy using our app now!
                    </Typography>
                  </Box>
                </Box>
                <Button
                  data-test-id="navigateToLandingPage"
                  onClick={this.navigateToLandingPage}
                  variant="contained"
                  sx={{
                    backgroundColor: '#A855F7',
                    color: '#fff',
                    width: '80%',
                    height: '56px',
                    borderRadius: '8px',
                    '&:hover': {
                      backgroundColor: '#9333EA',
                    },

                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontWeight: 700,
                    lineHeight: '28px',
                    textAlign: 'left',
                    textTransform: 'none'
                  }}
                >
                  Continue
                </Button>
              </Box>
            </Box>}
          </Container>
        </MainWrapper>
      </ThemeProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const CustomStepLabel = styled(StepLabel)(({ theme }) => ({
  '& .MuiStepLabel-label': {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    textAlign: 'left',
    '&:not(.Mui-active)': {
      color: '#0F172A',
    },
    '&:not(.Mui-active):not(.Mui-completed)': {
      color: '#64748B',
    },
    '&.Mui-active': {
      color: '#0F172A',
    },
    '&.Mui-completed': {
      color: '#0F172A',
    },
    '&:not(.Mui-completed)': {
      color: '#0F172A',
    },
  },
}));
const MainWrapper = styled(Box)(({ theme }) => ({

  "& .pageTitle": {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    letterSpacing: '-0.015em',
    textAlign: 'center',
  },
  "& .secondTitle": {
    marginTop: '10px',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '30px',
    fontWeight: 400,
    lineHeight: '40px',
    textAlign: 'center',

  }
}));
const CustomStepIcon = styled("div")(

  ({ active, completed }: { active: boolean; completed: boolean }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 24,
    height: 24,
    borderRadius: "50%",
    color: "#fff",
    ...(active && {
      backgroundColor: "#000",
    }),
    ...(completed && {
      backgroundColor: "#4caf50",

    }),
    ...(!active &&
      !completed && {
      backgroundColor: "#ddd",
      color: "#000",
    }),
  })
);
const StepIcon = (props: {
  active: boolean;
  completed: boolean;
  icon: React.ReactNode;
}) => {
  const { active, completed, icon } = props;

  return (
    <CustomStepIcon active={active} completed={completed}>
      {completed ? <>{activeStepIcon}</> : icon}
    </CustomStepIcon>
  );
};
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  "& .MuiStepConnector-line": {
    borderColor: theme.palette.grey[300],
    borderTopStyle: "dotted",
    borderWidth: "2px",
    [theme.breakpoints.down(600)]: {
      borderLeft: "2px dotted #ddd",
      borderBottom: "none",
      width: "0",
    },
  },
}));
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: '#334155',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  '&.Mui-focused': {
    color: '#334155',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));
// Customizable Area End