import React, { ReactNode } from "react";
// Customizable Area Start
import {
  AppBar, 
  Toolbar, 
  Typography, 
  ThemeProvider, 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  styled, 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Paper, 
  ListItemButton, 
  Divider, 
  Pagination, 
  IconButton,
  Avatar,
  Grid,
  Card,
  CardContent,
  Chip,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  Stack,
  Breadcrumbs,
  FormControl,
  FormLabel,
  TextField,
  FormHelperText
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import * as Yup from "yup"; 
import { Formik, Form, Field } from 'formik'; 
import LanguageIcon from '@mui/icons-material/Language';
import MenuIcon from '@mui/icons-material/Menu';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
interface Invoice {
  id: number;
  date: string;
  status: string;
  amount: string;
  plan: string;
}

const drawerWidth = 292;

const getBackgroundColor = (planId: any) => {
  switch (planId) {
    case 1:
      return '#F6F0FF';
    case 2:
      return '#F0E5FF';
    case 3:
      return '#E1CCFF';
    default:
      return '#FFFFFF';
  }
};

const validationSchema = Yup.object({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("description is required"),
});

const renderFormHelperText = (error?: string, touched?: boolean) => {
  if (error && touched) {
    return (
      <FormHelperText
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "20px",
          textAlign: "left",
          color: "#DC2626",
          marginLeft: "0px",
          
        }}
      >
        {error}
      </FormHelperText>
    );
  }
  return null;
};

// Customizable Area End

const configJSON = require("./config.js");

import LandingPageController, {
  Props
} from "./LandingPageController";

class LandingPage extends LandingPageController {
  static LandingPage: LandingPage;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getIconSrc = (item:any, activeIcon:any, inactiveIcon:any) => (this.state.activeItem === item ? activeIcon : inactiveIcon);
  primaryText=(value:string):ReactNode=>{
    return this.state.activeItem === value ? '' : value

  }
  secondaryText=(value:string):ReactNode=>{
    return this.state.activeItem === value ? value : ''

  }
  variantDrawer=(value:any)=>{
   return value<600 ? 'temporary' : 'persistent'
  }
  openDrawer=(value:any)=>{
    return value<600 ? this.state.mobileOpen : true
  }
  closeDrawer=(value:any)=>{
    return value<600 ? this.handleDrawerToggle : undefined
  }
  sxWidthDrawer=(value:any)=>{
    return value>600 ?drawerWidth:''
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const breadcrumbs = [
      <Box 
      component="a"
       sx={{
        color:'#475569',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '22px',
      }} key="1" color="inherit" 
      data-test-id="handleCloseContactUs" 
      onClick={this.handleCloseContactUs}
      >
        Subscription Settings
      </Box>,
      
      <Typography key="3" sx={{ 
      color: '#0F172A',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '22px',

       }}>
        Customize Your Own Features
      </Typography>,
    ];
    const invoices: Invoice[] = [
      { id: 12, date: 'Dec 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 11, date: 'Nov 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 10, date: 'Oct 1, 2023', status: 'Paid', amount: '250 QAR', plan: 'Basic' },
      { id: 9, date: 'Sep 1, 2023', status: 'Paid', amount: '500 QAR', plan: 'Advanced' },
      { id: 8, date: 'Aug 1, 2023', status: 'Paid', amount: '0 QAR', plan: 'Free' },
    ];
    const { currentPage } = this.state;
    const invoicesPerPage = 4;
    const indexOfLastInvoice = currentPage * invoicesPerPage;
    const indexOfFirstInvoice = indexOfLastInvoice - invoicesPerPage;
    const currentInvoices = invoices.slice(indexOfFirstInvoice, indexOfLastInvoice);
    return (
      <MainWrapper>
        <ThemeProvider theme={theme}>
          <Box sx={{ display: 'flex' }}>
            <Drawer
            variant={this.variantDrawer(this.state.isMobile)} 
            open={this.openDrawer(this.state.isMobile)}
            onClose={this.closeDrawer(this.state.isMobile)}
            sx={{
              width: this.sxWidthDrawer(this.state.isMobile),
              flexShrink: this.state.isMobile>600 ?0:'',
              [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: this.state.isMobile>600 ?'border-box':'' },
            }}
          >
          <Box sx={{ textAlign: 'center', marginTop: 3, marginBottom: 2 }}>
            <img
              src={this.state.logo}
              alt="logo"
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={this.state.isMobile<600 ? this.handleDrawerToggle : undefined}
            />
          </Box>

           <Box sx={{ overflow: 'auto', flexGrow: 1, marginTop: '154px' }}>
          <List>
            <ListItemButton
              data-test-id="navigateDrawerHome"
              onClick={() => this.navigateDrawer('Home')}
            >
              <CustomListItemIcon>
                <img
                  src={this.getIconSrc('Home', this.state.homeIconActive, this.state.homeIconInActive)}
                  alt="Home icon"
                />
              </CustomListItemIcon>
              <CustomListItemText 
               primary={this.primaryText('Home')}
               secondary={this.secondaryText('Home')}
              
              />
            </ListItemButton>

            <ListItemButton
              data-test-id="navigateDrawerSubscription"
              onClick={() => this.navigateDrawer('Subscription Settings')}
            >
              <CustomListItemIcon>
                <img
                  src={this.getIconSrc('Subscription Settings', this.state.subscriptionIconActive, this.state.subscriptionIconInActive)}
                  alt="Subscription Settings icon"
                />
              </CustomListItemIcon>
              <CustomListItemText 
              primary={this.primaryText('Subscription Settings')}
              secondary={this.secondaryText('Subscription Settings')}
              />
            </ListItemButton>

            <ListItemButton
              data-test-id="navigateDrawerInvoice"
              onClick={() => this.navigateDrawer('Invoices')}
            >
              <CustomListItemIcon>
                <img
                  src={this.getIconSrc('Invoices', this.state.invoiceIconActive, this.state.invoiceIconInActive)}
                  alt="Invoices icon"
                />
              </CustomListItemIcon>
              <CustomListItemText 
               primary={this.primaryText('Invoices')}
               secondary={this.secondaryText('Invoices')}
               />
            </ListItemButton>
          </List>
        </Box>

        <Box sx={{ paddingBottom: 2 }}>
          <List>
            <ListItemButton
              data-test-id="handleLogout"
              onClick={this.handleLogout}
            >
              <CustomListItemIcon>
                <img
                  src={this.state.logoutIcon}
                  alt="logout icon"
                />
              </CustomListItemIcon>
              <CustomListItemText primary="Logout" />
            </ListItemButton>
          </List>
        </Box>
        </Drawer>
          

           {this.state.activeItem =="Home" && <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>

              <AppBar position="sticky" sx={{ bgcolor: '#fff', color: '#000', boxShadow: 'none', borderBottom: '1px solid #E2E8F0' }}>
                <CustomToolbar>
                {this.state.isMobile<600 && (
                   <IconButton data-test-id="menuButton" onClick={this.handleDrawerToggle} sx={{ margin: 1, alignItems:'start', marginTop:'10px' }}>
                     <MenuIcon />
                   </IconButton>
                )}
                  <Typography className="homeTitleTypo" noWrap component="div">
                    Home
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit">
                      <LanguageIcon />
                    </IconButton>
                    <IconButton color="inherit">
                    <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/3.jpg"
                    sx={{ width: 24, height: 24 }}
                    />
                    </IconButton>
                  </Box>
                </CustomToolbar>
              </AppBar>

              <Box sx={{ padding: 3 }}>
                <Typography className="MainTitleTypo" gutterBottom>Overview</Typography>
                <Typography className="SecondTitleTypo" gutterBottom>Manage your subscription billing</Typography>
                <CustomPaper  className="upperPaper">

                  <Box sx={{ marginBottom: "-4px" }}>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between'
                    ,[theme.breakpoints.down(700)]:{
                      justifyContent: 'none',
                      flexDirection:'column'

                    }
                   }}>
                    <Typography className="MainPaperTitleTypo"  gutterBottom>Your current Plan</Typography>
                    <Typography className="SecondPaperTitleTypoLeft">500 QAR <span className="SecondPaperTitleTypoLeftSpan">/ month</span></Typography>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography className="SecondPaperTitleTypo" color="primary">Advanced Plan</Typography>
                    </Box>
                    <Divider sx={{marginTop:2,marginBottom:1}}/>
                    <List>
                      <CustomListItem>
                        <Typography className="featureTypo">Features</Typography>
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="All benefits from basic tier" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="Unlimited Livestream Views" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="4 Live Events/Month" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIcon" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="60 Min Live Duration" />
                      </CustomListItem>
                      <CustomListItem>
                        <img className="checkedIconLast" src={this.state.checkedIcon} alt="img"/>
                        <CustomListItemTextFeature primary="Additional Yearly Credit (500 QR), 5% Discount on Top-Ups" />
                      </CustomListItem>
                    </List>
                    <Divider sx={{marginTop:'5px',marginBottom:2}}/>
                    <Typography className="upcomingInvoiceTypo">Upcoming invoice</Typography>
                    <Typography className="dateTypo">1 Jan 2024</Typography>
                  </Box>
                </CustomPaper>
                <Typography className="MainTitleTypo" gutterBottom>Invoices</Typography>
                <Typography className="SecondTitleTypo" gutterBottom>Access all your previous invoices</Typography>
                <Box sx={{display: 'grid',overflowX: 'scroll'}}>
                <CustomTableContainer>
                  <Table>
                    <TableHead>
                      <CustomTableRow>
                        <CustomTableCellHeader>Invoice</CustomTableCellHeader>
                        <CustomTableCellHeader>Date</CustomTableCellHeader>
                        <CustomTableCellHeader>Status</CustomTableCellHeader>
                        <CustomTableCellHeader>Amount</CustomTableCellHeader>
                        <CustomTableCellHeader>Plan</CustomTableCellHeader>
                      </CustomTableRow>
                    </TableHead>
                    <TableBody>
                      {currentInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <CustomTableCellData>{`Invoice #${invoice.id} / ${invoice.date.split(' ')[0]} ${invoice.date.split(' ')[2]}`}</CustomTableCellData>
                          <CustomTableCellData>{invoice.date}</CustomTableCellData>
                          <CustomTableCellData>{invoice.status}</CustomTableCellData>
                          <CustomTableCellData>{invoice.amount}</CustomTableCellData>
                          <CustomTableCellData>{invoice.plan}</CustomTableCellData>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Box className='paginationBox'>
                    <CustomPagination
                      count={Math.ceil(invoices.length / invoicesPerPage)}
                      page={currentPage}
                      onChange={this.handlePageChange}
                      data-test-id="handlePageChange"
                    />
                  </Box>
                </CustomTableContainer>
                </Box>
                

              </Box>
            </Box>}
            {this.state.activeItem =="Subscription Settings" && <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default' }}>

              <AppBar position="sticky" sx={{ bgcolor: '#fff', color: '#000', boxShadow: 'none', borderBottom: '1px solid #E2E8F0' }}>
                <CustomToolbar>
                {this.state.isMobile<600 && (
                   <IconButton data-test-id="menuButton" onClick={this.handleDrawerToggle} sx={{ margin: 1, alignItems:'start', marginTop:'10px' }}>
                     <MenuIcon />
                   </IconButton>
                )}
                  <Typography className="homeTitleTypo" noWrap component="div">
                  Subscription Settings
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit">
                      <LanguageIcon />
                    </IconButton>
                    <IconButton color="inherit">
                    <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/3.jpg"
                    sx={{ width: 24, height: 24 }}
                    />
                    </IconButton>
                  </Box>
                </CustomToolbar>
              </AppBar>

              {!this.state.contactUs &&

              <Box sx={{ padding: 3 }}>
                <Typography className="MainTitleTypo" gutterBottom>Subscription Plans</Typography>
                <Typography className="SecondTitleTypo" gutterBottom>Your current plan: <span style={{fontWeight:'700'}}>Advanced Plan</span></Typography>
                <Box>
              <Box display="flex" justifyContent="center" mb={7} sx={{ marginTop: '50px' }}>
                <Box>
                  <StyledToggleButtonGroup
                    data-test-id="handleToggleChange"
                    value={this.state.plan}
                    exclusive
                    onChange={this.handleToggleChange}
                    aria-label="plan selection"
                  >
                    <StyledToggleButton value="annual" aria-label="annual">
                      Annual
                    </StyledToggleButton>
                    <StyledMonthlyToggleButton value="monthly" aria-label="monthly">
                      Monthly
                    </StyledMonthlyToggleButton>
                  </StyledToggleButtonGroup>
                </Box>

              </Box>
              <Grid container spacing={4}>

                {this.state.subscriptions.sort((a: any, b: any) => a.attributes.id - b.attributes.id).map((plan: any, index: any) => (
                   
                  <Grid item xs={12} sm={6} lg={3} xl={3} key={index}>
                    <Card
                      variant="outlined"
                      sx={{
                        height: '100%',
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        borderRadius: 2,
                        borderColor: plan.borderColor,
                        "&:hover": {
                          boxShadow: "0 0 0 2px #6200EA",
                        },
                      }}
                    >
                      <CardContent sx={{ flex: 1, textAlign: "center" }}>

                        <Box sx={{ display: 'flex' }}>
                          <Chip sx={{
                            color: '#1E293B',
                            padding: '8px 16px',
                            height: '40px',
                            backgroundColor: getBackgroundColor(plan.attributes.id),
                            textAlign: 'left',
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '24px',

                          }} label={plan.attributes.subscription_en_name} size="small" />
                        </Box>
                        <Typography
                          sx={{
                            marginTop: '16px',
                            color: '#1E293B',
                            fontFamily: 'Aeonik',
                            fontSize: '24px',
                            fontWeight: 700,
                            lineHeight: '26px',
                            textAlign: 'left',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          {this.state.plan == "annual" && (
                            <>
                              <span>
                                QAR {plan.attributes.yearly_price}
                              </span>

                              <span style={{
                                color: '#1E293B',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                              }}>&nbsp; Per Year</span>
                            </>
                          )}

                          {this.state.plan == "monthly" && (
                            <>
                              <span>
                                QAR {plan.attributes.monthly_price}
                              </span>

                              <span style={{
                                color: '#1E293B',
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                lineHeight: '22px',
                                textAlign: 'left',
                              }}>&nbsp; Per Month</span>
                            </>
                          )}

                        </Typography>


                        <Divider sx={{ marginTop: '24px' }} />
                        <Box sx={{ marginTop: '24px' }}>
                          {plan.attributes.feature.map((feature: any, idx: any) => (

                            <Typography
                              key={idx}
                              variant="body2"
                              color="text.secondary"
                              sx={{
                                display: "flex",
                                textAlign: 'left',
                                mb: 1,
                                fontFamily: 'Inter',
                                fontSize: '18px',
                                fontWeight: '400',
                                lineHeight: '21.78px',
                                gap: '8px'
                              }}
                            >
                              <span>
                                <img src={this.state.checkedIcon} />
                              </span>
                              {feature.feature_name_en}
                            </Typography>
                          ))}

                        </Box>
                      </CardContent>
                      <Box p={2}>
                        <Button
                          data-test-id="handleGetStarted"
                          onClick={() => { this.handleGetStarted(plan.attributes.id) }}
                          variant="outlined"
                          fullWidth
                          sx={{
                            textTransform: "none",
                            borderRadius: '8px',
                            height: '56px',
                            border: '1px solid #9E52F6',
                            color: "#9b59b6",
                            fontFamily: 'Inter',
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            textAlign: 'left',
                            "&:hover": {
                              backgroundColor: "#9b59b6",
                              color: "#fff",
                            },
                          }}
                        >
                          Get Started
                        </Button>
                      </Box>
                    </Card>
                  </Grid>
                ))}
                <Grid item xs={12} sm={6} lg={3} key={4}>
                  <Card
                    variant="outlined"
                    sx={{
                      height: '100%',
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      borderRadius: 2,

                      "&:hover": {
                        boxShadow: "0 0 8px rgba(155, 89, 182, 0.5)",
                      },
                    }}
                  >
                    <CardContent sx={{ flex: 1, textAlign: "center" }}>

                      <Box sx={{ display: 'flex' }}>
                        <Typography sx={{
                          color: '#1E293B', padding: '8px 0px',
                          textAlign: 'left',
                          fontFamily: 'Inter',
                          fontSize: '18px',
                          fontWeight: 600,
                          lineHeight: '24px',

                        }} >Customize Your Own Features</Typography>
                      </Box>



                      <Divider sx={{ marginTop: '24px' }} />
                      <Box sx={{ marginTop: '24px' }}>

                        <Typography

                          variant="body2"
                          color="text.secondary"
                          sx={{
                            display: "flex",
                            textAlign: 'left',
                            mb: 1,
                            fontFamily: 'Inter',
                            fontSize: '18px',
                            fontWeight: '400',
                            lineHeight: '21.78px',
                            gap: '8px'

                          }}
                        >
                          Submit your request now and you'll get an offer from us as soon as possible.

                        </Typography>


                      </Box>
                    </CardContent>
                    <Box p={2}>
                      <Button
                        data-test-id="handleCustomizePlan"
                        onClick={this.handleCustomizePlan}
                        variant="outlined"
                        fullWidth
                        sx={{
                          textTransform: "none",
                          height: '56px',
                          borderColor: "#9b59b6",
                          borderRadius: '8px',
                          color: "#9b59b6",
                          fontFamily: 'Inter',
                          fontSize: '16px',
                          fontWeight: 700,
                          lineHeight: '24px',
                          "&:hover": {
                            backgroundColor: "#9b59b6",
                            color: "#fff",
                          },
                        }}
                      >
                        Customize My Plan
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              </Grid></Box></Box>}

              {this.state.contactUs &&
              <Box sx={{ padding: 3 }}>
                 <Stack spacing={2}>
    
   
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {breadcrumbs}
      </Breadcrumbs>
                 </Stack>
  <Typography className="MainTitleTypo" gutterBottom>Contact us</Typography>
  <Typography className="SecondTitleTypo" gutterBottom>Our team would love to help you.</Typography>
              <Box>
           <Box display="flex"  mb={7} sx={{ marginTop: '30px' }}>
              <Box>
               <Formik
                data-test-id = "formik"
                initialValues={{ title: "", description: "" }}
                validationSchema={validationSchema}
                onSubmit={(values):any => {
                
                }}
              >
                {({ handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
                  <Form>
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabel htmlFor="title">Title</StyledFormLabel>
                      <StyledField
                        as={TextField}
                        id="title"
                        data-test-id="title"
                        name="title"
                        placeholder="Enter title"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.title) && touched.title}
                        InputLabelProps={{ shrink: true }}
                      
                      />
                      {renderFormHelperText(errors.title, touched.title)}
                    </FormControl>
                    
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabel htmlFor="description">Description</StyledFormLabel>
                      <StyledDescriptionField
                        as={TextField}
                        id="description"
                        multiline
                        rows={4}
                        defaultValue="Leave a message..."
                        data-test-id="description"
                        name="description"
                        placeholder="Leave a message..."
                        value={values.description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.description) && touched.description}
                        InputLabelProps={{ shrink: true }}
                       
                      />
                      {renderFormHelperText(errors.description, touched.description)}
                    </FormControl>
    
                 
                    <StyledFieldButton
                      variant="contained"
                      data-test-id='handleSubmit'
                      fullWidth
                      type="submit"
                     
                    >
                      Send Message
                    </StyledFieldButton>
                  </Form>
                )}
              </Formik>
  </Box>

</Box>
</Box>
              </Box>}
              
              </Box>}
            {this.state.activeItem =="Invoices" && <Box component="main" sx={{ flexGrow: 1, 
              bgcolor: 'background.default' 
              }}>

              <AppBar 
              position="sticky" 
              sx={{ bgcolor: '#fff', 
              color: '#000', 
              boxShadow: 'none', 
              borderBottom: '1px solid #E2E8F0' }}>
                <CustomToolbar>{this.state.isMobile<600 && (<IconButton data-test-id="menuButton" onClick={this.handleDrawerToggle} sx={{ margin: 1, alignItems:'start', marginTop:'10px' }}>
                     <MenuIcon />
                   </IconButton>)}
                  <Typography className="homeTitleTypo" noWrap component="div">
                  Invoices
                  </Typography>

                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton color="inherit">
                      <LanguageIcon />
                    </IconButton>
                    <IconButton color="inherit">
                    <Avatar
                    alt="Remy Sharp"
                    src="https://mui.com/static/images/avatar/3.jpg"
                    sx={{ width: 24, height: 24 }}
                    />
                    </IconButton>
                  </Box>
                </CustomToolbar>
              </AppBar>

              <Box
               sx={{ padding: 3 }}>
                <Typography
                 className="MainTitleTypo" 
                 gutterBottom>Invoices
                 </Typography>
                <Typography 
                className="SecondTitleTypo" 
                gutterBottom>Access all your previous invoices
                </Typography>
                <Box 
                sx={{display: 'grid',
                overflowX: 'scroll'}}>
                  <CustomTableContainer>  


                  <Table><TableHead>
                       <CustomTableRow>
                        <CustomTableCellHeader>
                          Invoice
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Date
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Status
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Amount
                          </CustomTableCellHeader>
                        <CustomTableCellHeader>
                          Plan
                        </CustomTableCellHeader>
                      </CustomTableRow></TableHead>
                    <TableBody>
                       {currentInvoices.map((invoice) => (
                        <TableRow key={invoice.id}>
                          <CustomTableCellData>
                            {`Invoice #${invoice.id} / ${invoice.date.split(' ')[0]} ${invoice.date.split(' ')[2]}`}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.date}
                          </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.status}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.amount}
                            </CustomTableCellData>
                          <CustomTableCellData>
                            {invoice.plan}
                            </CustomTableCellData>
                          </TableRow>))}</TableBody>
                  </Table>
                  <Box 
                  className='paginationBox'>
                    <CustomPagination count={Math.ceil(invoices.length / invoicesPerPage)}
                    page={currentPage} onChange={this.handlePageChange}
                      data-test-id="handlePageChange"/></Box>
                </CustomTableContainer></Box></Box></Box>}</Box>
        </ThemeProvider>
      </MainWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .homeTitleTypo": {
    display: 'flex',
    alignItems: 'center',
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }
  },
  "& .upperPaper": {
    padding: '16px',
    marginBottom: '42px',
    marginTop: '45px',
    [theme.breakpoints.down('md')]: { 
      padding: '14px',
      marginBottom: '35px',
      marginTop: '40px',
    },
    [theme.breakpoints.down('sm')]: { 
      padding: '12px',
      marginBottom: '30px',
      marginTop: '35px',
    }
  },
  "& .MainTitleTypo": {
    marginTop:'40px',
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '2.5rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.75rem',
      lineHeight: '2.25rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.5rem',
      lineHeight: '2rem',
    }
  },
  "& .SecondTitleTypo": {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    letterSpacing: '-0.005em',textAlign: 'left',
     [theme.breakpoints.down('md')]: { 
      fontSize: '1.375rem',lineHeight: '1.875rem',
    },
     [theme.breakpoints.down('sm')]: { 
      fontSize: '1.25rem',lineHeight: '1.75rem',
    }},
  "& .MainPaperTitleTypo": {
    color: '#1E293B',fontFamily: 'Inter',
    fontSize: '1.125rem',fontWeight: 400,
    lineHeight: '1.625rem',textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1rem',lineHeight: '1.5rem',
     },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '0.875rem', lineHeight: '1.25rem',
  }
   },
    "& .SecondPaperTitleTypo": {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '2rem',
    letterSpacing: '-0.005em',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      fontSize: '1.375rem',
      lineHeight: '1.875rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
    }
  },
   "& .SecondPaperTitleTypoLeft": {color: '#1E293B',fontFamily: 'Inter',
           fontSize: '1.25rem',fontWeight: 700,lineHeight: '1.75rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1.125rem',lineHeight: '1.625rem',
     },
     [theme.breakpoints.down('sm')]: { fontSize: '1rem',lineHeight: '1.5rem',}
   },"& .SecondPaperTitleTypoLeftSpan": {
    color: '#1E293B',fontFamily: 'Inter',fontSize: '1.25rem',fontWeight: 400,
    lineHeight: '1.75rem',textAlign: 'left',
    [theme.breakpoints.down('md')]: { fontSize: '1.125rem',lineHeight: '1.625rem',
    },
    [theme.breakpoints.down('sm')]: { fontSize: '1rem',lineHeight: '1.5rem',
     }
   }, "& .featureTypo": {
    marginBottom: '10px',color: '#1E293B',fontFamily: 'Inter',fontSize: '1.125rem',
    fontWeight: 700,lineHeight: '1.625rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1rem',lineHeight: '1.5rem',
    },
     [theme.breakpoints.down('sm')]: { fontSize: '0.875rem',lineHeight: '1.25rem',
     }},"& .upcomingInvoiceTypo": {
    color: '#1E293B',fontFamily: 'Inter',fontSize: '1.125rem',fontWeight: 400,
    lineHeight: '1.625rem',textAlign: 'left',
     [theme.breakpoints.down('md')]: { fontSize: '1rem',lineHeight: '1.5rem',
     },
     [theme.breakpoints.down('sm')]: { fontSize: '0.875rem',lineHeight: '1.25rem',
     }},"& .dateTypo": {
    marginTop: '5px',
    color: '#1E293B',
    fontFamily: 'Inter',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
    textAlign: 'left',
    [theme.breakpoints.down('md')]: { 
      fontSize: '1.125rem',
      lineHeight: '1.625rem',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '1rem',
      lineHeight: '1.5rem',
    }
  },
  "& .checkedIcon": {
    marginRight: "8px",
    marginTop: '-5px'
  },
  "& .checkedIconLast": {
    marginRight: "8px",
    marginTop: '-5px',
    [theme.breakpoints.down(527)]:{
      marginTop:'-28px'
    }
  },
  "& .paginationBox": {
    marginTop: '15px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: { 
      marginTop: '10px',
      marginBottom: '10px',
    }
  }
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
  justifyContent: 'space-between',
  minHeight: '92px !important',
  [theme.breakpoints.down('md')]: { 
    minHeight: '82px !important',
  },
  [theme.breakpoints.down('sm')]: { 
    minHeight: '72px !important',
  }
}));

const CustomPaper = styled(Paper)(({ theme }) => ({
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  padding: '16px',
  [theme.breakpoints.down('md')]: { 
    padding: '14px',
  },
  [theme.breakpoints.down('sm')]: { 
    padding: '12px',
  }
}));

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
  border: '1px solid #CBD5E1',
  borderRadius: '8px',
  marginTop: '50px',
  width:'auto',
  [theme.breakpoints.down('md')]: { 
    marginTop: '40px',
  },
  [theme.breakpoints.down('sm')]: { 
    marginTop: '30px',
  },
  
}));

const CustomListItem = styled(ListItem)(({ theme }) => ({
  padding: '3px 0px',
  "& .MuiListItem-root": {
    padding: '3px 0px'
  }
}));

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: '#E2E8F0',
}));

const CustomListItemText = styled(ListItemText)(({ theme }) => ({
  '& .MuiListItemText-primary': {
    cursor:'pointer',
    color: '#64748B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: { 
      fontSize: '14px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
  '& .MuiListItemText-secondary': {
    cursor:'pointer',
    color: '#6200EA',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    [theme.breakpoints.down('md')]: { 
      fontSize: '14px',
      lineHeight: '26px',
    },
    [theme.breakpoints.down('sm')]: { 
      fontSize: '18px',
      lineHeight: '24px',
    }
  },
}));

const CustomListItemTextFeature = styled(ListItemText)(({ theme }) => ({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '18px',
  fontWeight: 400,
  lineHeight: '26px',
  textAlign: 'left',
  '& .MuiListItemText-primary': {
    fontFamily: 'Inter',
    fontSize: '18px', 
    fontWeight: 400,
    lineHeight: '26px',
  },
  [theme.breakpoints.down('md')]: { 
    fontSize: '17px',
    lineHeight: '24px',
    '& .MuiListItemText-primary': {
      fontSize: '17px',
    lineHeight: '24px',
    },
  },
  [theme.breakpoints.down('sm')]: { 
   
    '& .MuiListItemText-primary': {
      fontSize: '16px',
      lineHeight: '22px',
    },
  }
}));

const CustomListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  minWidth: '32px',
  marginTop: '-4px',
  color: '#64748B'
}));

const CustomTableCellHeader = styled(TableCell)(({ theme }) => ({
  color: '#64748B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: { 
    fontSize: '18px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

const CustomTableCellData = styled(TableCell)(({ theme }) => ({
  color: '#1E293B',
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 400,
  lineHeight: '28px',
  textAlign: 'left',
  [theme.breakpoints.down('md')]: { 
    fontSize: '18px',
    lineHeight: '26px',
  },
  [theme.breakpoints.down('sm')]: { 
    fontSize: '16px',
    lineHeight: '24px',
  }
}));

const CustomPagination = styled(Pagination)(({ theme }) => ({
  "& .MuiPaginationItem-root": {
    color: '#0F172A',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '18px',
    textAlign: 'center',
    minWidth: '32px',
    height: '32px',
    borderRadius: '4px',
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: '#6200EA',
    fontWeight: 700,
    backgroundColor: 'transparent',
  },
  "& .MuiPaginationItem-root:hover": {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
  "& .MuiPaginationItem-ellipsis": {
    color: '#0F172A',
  },
  "& .MuiPaginationItem-icon": {
    color: '#0F172A',
    fontSize: '18px',
  },
}));

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#9E52F6',
  borderRadius: '56px',
  padding: '5.5px',
  boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
  height: '41px',
  border: 0,
  width: '300px',
}));

const StyledToggleButton = styled(ToggleButton)(({
  theme
}) => ({
  textTransform: 'none', padding: '0 16px',borderRadius: '30px !important', borderLeft: '0',
  borderRight: '0', borderTop: '0',borderBottom: '0',
  "&:hover": {backgroundColor: 'transparent', color: '#0F172A',},
  '&.Mui-selected': {backgroundColor: '#FFFFFF', color: '#0F172A',width: 150, fontWeight: '700',
    fontSize: '18px',fontFamily: 'Inter', lineHeight: '26px',"&:hover": {backgroundColor: '#FFFFFF', color: '#0F172A',
    },},
  '&:not(.Mui-selected)': {color: '#FFFFFF', backgroundColor: '#9E52F',
    width: 150, fontSize: '20px',lineHeight: '28px', fontWeight: 400,},
  }));

  const StyledMonthlyToggleButton = styled(StyledToggleButton)({
    textTransform: 'none', padding: '0 16px',borderRadius: '30px !important', borderLeft: '0',
    borderRight: '0', borderTop: '0',borderBottom: '0',
    "&:hover": {backgroundColor: 'transparent', color: '#0F172A',
    },
    '&.Mui-selected': {
      backgroundColor: '#FFFFFF', color: '#0F172A',width: 150, fontWeight: '700',
      fontSize: '18px',fontFamily: 'Inter', lineHeight: '26px',"&:hover": {backgroundColor: '#FFFFFF', color: '#0F172A',
      },
    },
    '&:not(.Mui-selected)': {color: '#FFFFFF', backgroundColor: '#9E52F',
      width: 150, fontSize: '20px',lineHeight: '28px', fontWeight: 400,},
   });

   const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    color: '#334155',fontFamily: 'Inter',
    fontSize: '24px',fontWeight: 700,
    lineHeight: '32px',letterSpacing: '-0.005em',
    textAlign: 'left',
    '&.Mui-focused': {color: '#334155',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px',lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px',lineHeight: '22px',
    },
  }));

  const StyledDescriptionField = styled(Field)(({ theme }) => ({
    borderRadius: "12px",
    "& .MuiInputBase-root": {position: "relative",width: "100%",
      display: "flex",padding: "0px 0px",alignItems: "center",flexShrink: 0,
      background: theme.palette.common.white,marginTop: "4px",borderRadius: "8px",color: '#94A3B8',
      fontSize: "18px",fontStyle: "normal",fontWeight: 400,lineHeight: "26px",
      fontFamily: "Inter",[theme.breakpoints.down('md')]: {fontSize: "16px",height: "48px",
      },
      [theme.breakpoints.down('sm')]: {fontSize: "14px",height: "40px",
      },
      [theme.breakpoints.down('xs')]: {fontSize: "12px",height: "32px",
      },},
    "& .MuiOutlinedInput-input": {padding: "8px",color: '#475569'
  }
   }));
   const StyledField = styled(Field)(({ theme }) => ({
    borderRadius: "12px",
    "& .MuiInputBase-root": {
      position: "relative",width: "100%",height: "56px",
      display: "flex",padding: "24px 0px",
      alignItems: "center",flexShrink: 0,
      background: theme.palette.common.white,marginTop: "4px",
      borderRadius: "8px",color: '#94A3B8',
      fontSize: "18px",fontStyle: "normal",
      fontWeight: 400,lineHeight: "26px",
      fontFamily: "Inter",
      [theme.breakpoints.down('md')]: {
        fontSize: "16px",height: "48px",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: "14px",height: "40px",
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: "12px",height: "32px",
      },
    },
    "& .MuiOutlinedInput-input": {
      padding: "8px",color: '#475569'
    }
  }));

  const StyledFieldButton = styled(Button)(({ theme }) => ({
    marginTop:'25px',
    width: '100%',height: '56px',
    padding: '16px',gap: '8px',
    borderRadius: '8px',backgroundColor: "#F1F5F9",
    color: "#64748B",fontFamily: 'Inter',
    fontSize: '20px',fontWeight: 700,
    lineHeight: '28px',textTransform: 'none',
    boxShadow: 'none',
    "&:hover": {
      backgroundColor: '#9E52F6 !important',color: "#FFFFFF",
    },
    "&:active": {
      backgroundColor: '#9E52F6 !important',color: "#FFFFFF",
    },
    "& .MuiTypography-root": {
      fontFamily: 'Inter !important',fontSize: '18px !important',
      fontWeight: '400 !important',lineHeight: '26px !important',
      textAlign: 'left !important',
    },
    [theme.breakpoints.down('md')]: {fontSize: '18px',
      height: '48px',
    },
    [theme.breakpoints.down('sm')]: {fontSize: '16px',
      height: '40px',
    },
    [theme.breakpoints.down('xs')]: {fontSize: '14px',
      height: '32px',
    },
  }));


// Customizable Area End

export default LandingPage;
