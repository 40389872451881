Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.getSubscriptionListAPiEndPoint = "bx_block_custom_user_subs/user_subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.subscriptionApiContentType = "application/json";
exports.getUserSubscriptionsAPiEndPoint = "bx_block_custom_user_subs/user_subscriptions";
exports.getUserSubscriptionsAPiMethod = "GET";
exports.getUserSubscriptionsApiContentType = "application/json";
// Customizable Area End