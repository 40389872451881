import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { checkedIcon,successLogo } from "./assets";
import  {getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  route: any;
  data: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  subscriptions: any;
  data: any;
  activeStep: number;
  isVertical:boolean;
  checkedIcon:string;
  plan:string;
  userSubscriptions:any
  subscriptionsPage:boolean;
  checkoutPage:boolean;
  confirmationPage:boolean;
  showToast: boolean;
  toastMessage: string;
  successLogo:string;
  userSelectedSubscriptions:{
    attributes: {
      id: string,
      subscription_en_name: string,
      subscription_ar_name: string,
      monthly_price: string,
      yearly_price: string,
      active: boolean,
      feature: [
        {
          feature_name_ar: string,
          feature_name_en: string
        },
      ]
    }
  }
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CustomisableusersubscriptionsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getListCallId: any;
  getSubscriptionListCallId:any ;
  getSelectedSubscriptionCallId:any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      token: "",
      subscriptions: [],
      data: null,
      activeStep: 0,
      isVertical:false,
      checkedIcon:checkedIcon.default,
      plan:'annual',
      userSubscriptions:[],
      subscriptionsPage:true,
      checkoutPage:false,
      confirmationPage:false,
      showToast: false,
      toastMessage: '',
      successLogo:successLogo.default,
      userSelectedSubscriptions:{
        attributes: {
          id: "",
          subscription_en_name: "",
          subscription_ar_name: "",
          monthly_price: "",
          yearly_price: "",
          active: false,
          feature: [
            {
              feature_name_ar: "",
              feature_name_en: ""
            },
          ]
        }
      }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const data = message.getData(
        getName(MessageEnum.NavigationPayLoadMessage)
      );
      this.setState({ data: data });
    }
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token }, () => {
        this.fetchSubscriptionsList();
      });
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getListCallId) {
          this.setState({
            subscriptions: responseJson.data,
          });
        }
      }
    }
    this.receive1(message);
    this.receive2(message);
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    try {
      const token = await getStorageData("token");
      if (!token) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistrationBrand");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      } else {
        this.getSubscriptionsList(); 
      }
    } catch (error) {

    }
    
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  fetchSubscriptionsList = () => {
    // Customizable Area Start
    const header = {
      token: this.state.token,
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    // Customizable Area End
  };

  gotoSubDetailScreen(item: any) {
    // Customizable Area Start
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "SubscriptionDetails"
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    const raiseMessage: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessage.addData(getName(MessageEnum.NavigationPayLoadMessage), item);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(msg);
    // Customizable Area End
  }

  // Customizable Area Start
    
    handleNext = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep + 1,
      }));
    };
  
    handleBack = () => {
      this.setState((prevState) => ({
        activeStep: prevState.activeStep - 1,
      }));
    };
 
    handleReset = () => {
      this.setState({
        activeStep: 0,
      });
    };

    getSubscriptionsList = () => {
      const header = {
        "Content-Type": configJSON.subscriptionApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getSubscriptionListCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getSubscriptionListAPiEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getSubscriptionAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    receive1=(message:Message)=>{
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
  
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getSubscriptionListCallId) {
            this.setState({
              userSubscriptions: responseJson.data,
            });
          }
        }
      }

    };
    receive2=(message:Message)=>{
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
  
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
  
        if (apiRequestCallId && responseJson) {
          if (apiRequestCallId === this.getSelectedSubscriptionCallId) {
            this.setState({
              userSelectedSubscriptions: responseJson.data,
            });
          }
        }
      }

      
    };

  
    handleCloseToast = () => {
      this.setState({ showToast: false, toastMessage: '' });
    };

    formatCardNumber = (value: string) => {
      return value.replace(/\D/g, '').replace(/(\d{4})/g, '$1 ').trim().slice(0, 19);
    };
  
    formatExpiryDate = (value: string) => {
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{0,4})/, '$1/$2')
        .substring(0, 7);
    };

    navigateToLandingPage=()=>{
      const message: Message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "LandingPage");
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      this.send(message);

    }
    handleGetStarted=(id:string)=>{
      this.setState({subscriptionsPage:false,checkoutPage:true,confirmationPage:false})
      this.handleNext();

      const header = {
        "Content-Type": configJSON.subscriptionApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getSelectedSubscriptionCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getSubscriptionListAPiEndPoint}/${id}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getSubscriptionAPiMethod
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);

    }
    handlePaymentForm=(values:any)=>{
      this.setState({subscriptionsPage:false,checkoutPage:false,confirmationPage:true})
      this.handleNext()
    }
    handleNavigateBack=()=>{
      this.setState({subscriptionsPage:true,checkoutPage:false,confirmationPage:false})
      this.handleBack();
    }
    handleToggleChange = (newPlan: any) => {
      if (newPlan !== null && newPlan==="annual") {
        this.setState({ plan: "monthly" });
      }else{
        this.setState({ plan: "annual" });

      }
    };
    
  // Customizable Area End
}
