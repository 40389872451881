import React from "react";
// Customizable Area Start
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Box,
  Typography,
  ThemeProvider,
  styled,
  FormLabel,
  FormHelperText,
  Snackbar,
  Alert,
  Select,
  MenuItem,
  ButtonProps
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const validationSchema = Yup.object({
  businessName: Yup.string().required('You need to write your business name'),
  websiteLink: Yup.string().url('Invalid URL').required('You need to write the website link'),
  category: Yup.array()
  .min(1, 'Select at least one category')
  .required('You need to select the Category'),
  language: Yup.string().required('You need to select the language'),
  email: Yup.string()
  .required("Email is required")
  .test("no-spaces", "Please remove unnecessary space", (value) => {
    if (!value) return true;
    return !/\s/.test(value);
  })
  .email("Invalid email format"),
  taxIdentificationNumber: Yup.string().required('You need to write the tax identification number'),
  file: Yup.mixed()
    .required('You need to select a file')
    .test('fileSize', 'File size exceeds the 5MB limit.', value => {
      return value && value.size <= 5 * 1024 * 1024;
    })
    .test('fileType', 'Invalid file type. Only JPG, PNG, and PDF are allowed.', value => {
      const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      return value && allowedTypes.includes(value.type);
    }),
});



function renderFormHelperText(error: any, touched: any, stateError: any) {
  const errorMessage = (error && touched && error) || stateError;

  if (errorMessage) {
    return (
      <FormHelperText
        style={{
          fontFamily: 'Inter',
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '24px',
          textAlign: 'left',
          color: "#DC2626",
          marginLeft: "0px"
        }}
      >
        {errorMessage}
      </FormHelperText>
    );
  }
  return null;
}

const languages = [
  { value: '', label: 'Select language' },
  { value: 'english', label: 'English' },
  { value: 'arabic', label: 'Arabic' },
];

// Customizable Area End

const configJSON = require("./config.js");

import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";

class EmailAccountRegistrationBrandProfile extends EmailAccountRegistrationController {
  static EmailAccountRegistrationBrandProfile: EmailAccountRegistrationBrandProfile;

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getToastMessage = () => {
    const { showToastMessage } = this.state;
    if (typeof showToastMessage === 'string') {
      return showToastMessage;
    } else {
      return "Invalid message";
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainWrapper>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0}
            height="100vh">
            <Snackbar
              open={this.state.showToast}
              autoHideDuration={6000}
              onClose={this.handleCloseToast}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              sx={{ top: '0px', justifyContent: 'center' }}
            >
              <Alert
                onClose={this.handleCloseToast}
                sx={{ width: '100%' }}
                severity="error"
              >

                {this.getToastMessage()}
              </Alert>

            </Snackbar>

            <Grid item xs={12} sm={5} className="grid_left" height="100%">
              <Box className="main_box_left" style={{ position: 'relative' }}>
                <img src={this.state.leftBannerImg} alt="banner" style={{ width: '100%', height: '100%' }} />
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <img src={this.state.logo} alt="logo" style={{ width: '100%', objectFit: 'cover' }} />
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              sx={{
                height: '100%',
                display: 'block',
                overflowY: 'auto',
                paddingTop: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingBottom: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingLeft: { xs: '16px', sm: '32px', md: '64px', lg: '137px' },
                paddingRight: { xs: '16px', sm: '32px', md: '64px', lg: '120px' },
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >

              <Typography className="mainTitle" gutterBottom>
                Let’s set you up!
              </Typography>


              <Formik
                enableReinitialize
                data-test-id="formik"
                initialValues={{
                  businessName: this.state.businessName,
                  websiteLink: '',
                  category: [],
                  language: '',
                  email: this.state.email,
                  phoneNumber: String(this.state.phoneNumber),
                  countryCode: '',
                  taxIdentificationNumber: '',
                  file: null as File | null,
                  businessRegistrationProof: '',
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.handleBrandProfileData(values);
                  
                }}
              >
                {({ handleChange, handleBlur, values, errors, touched, setFieldValue, setFieldTouched }) => (
                  <Form>
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="businessName">Business Name</StyledFormLabelBrandProfile>
                      <StyledFieldBrandProfile
                        disabled
                        as={TextField}
                        id="businessName"
                        name="businessName"
                        data-test-id="businessName"
                        placeholder="Enter your business name"
                        value={values.businessName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.businessName) && touched.businessName}
                        InputLabelProps={{ shrink: true }}
                      />

                      {renderFormHelperText(errors.businessName, touched.businessName, null)}
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="websiteLink">Website Link</StyledFormLabelBrandProfile>
                      <StyledFieldBrandProfile
                        as={TextField}
                        data-test-id="websiteLink"
                        id="websiteLink"
                        name="websiteLink"
                        placeholder="Enter website link"
                        value={values.websiteLink}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.websiteLink) && touched.websiteLink}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />

                      {renderFormHelperText(errors.websiteLink, touched.websiteLink, null)}
                    </FormControl>

<FormControl fullWidth margin="normal">
  <StyledFormLabelBrandProfile htmlFor="category">Category</StyledFormLabelBrandProfile>
  <StyledSelectField
    MenuProps={{
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      PaperProps: {
        style: {
          minWidth: '150px',
          borderRadius: '8px',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
          marginTop: '4px',
          border: '1px solid #F3EBFF',
        },
      },
      MenuListProps: {
        sx: {
          '& .MuiMenuItem-root': {
            '&.Mui-selected': {
              backgroundColor: '#F3EBFF',
              '&:hover': {
                backgroundColor: '#F1F5F9',
              },
            },
            '&:hover': {
              backgroundColor: '#F1F5F9',
            },
          },
        },
      },
    }}
    data-test-id="category"
    id="category"
    name="category"
    value={values.category} 
    onChange={handleChange}
    onBlur={handleBlur}
    displayEmpty
    multiple 
    renderValue={(selected:any) => {
      const selectedValues = selected as string[];
      if (selectedValues.length === 0) {
        return <span style={{ color: '#94A3B8' }}>Choose category</span>;
      }

     
      const selectedNames = selectedValues
        .map((id) => {
          const category = this.state.categoryList.find((option) => option.attributes.id.toString() === id);
          return category ? category.attributes.name_en : null;
        })
        .filter(Boolean); 

      return selectedNames.join(', ');
    }}
    inputProps={{ 'aria-label': 'Category' }}
  >
    
    {this.state.categoryList.map((option) => (
            <MenuItem key={option.id} value={option.attributes.id.toString()} disabled={!option.attributes.name_en}>
              {option.attributes.name_en}
            </MenuItem>
          ))}
  </StyledSelectField>
  {renderFormHelperText(errors.category, touched.category, null)}
</FormControl>

                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="language">Language</StyledFormLabelBrandProfile>
                      <StyledSelectField
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right',
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'right',
                          },
                          PaperProps: {
                            style: {
                              minWidth: '150px',
                              borderRadius: '8px',
                              backgroundColor: '#FFFFFF',
                              boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
                              marginTop: '4px',
                              border: '1px solid #F3EBFF'
                            },
                          },
                          MenuListProps: {
                            sx: {
                              '& .MuiMenuItem-root': {
                                '&.Mui-selected': {
                                  backgroundColor: '#F3EBFF',
                                  '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                  },
                                },
                                '&:hover': {
                                  backgroundColor: '#F1F5F9',
                                },
                              },
                            },
                          },

                        }}
                        data-test-id="language"
                        id="language"
                        name="language"
                        value={values.language}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.language) && touched.language}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Language' }}
                      >
                        {languages.map((option) => (
                          <MenuItem key={option.value} value={option.value} disabled={!option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </StyledSelectField>
                      {renderFormHelperText(errors.language, touched.language, null)}
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="phoneNumber">
                        Phone Number (optional)
                      </StyledFormLabelBrandProfile>
                      <StyledPhoneInput>
                        <PhoneInput
                          inputProps={{
                            id: "phoneNumber",
                            name: "phoneNumber",
                            "data-test-id": "phoneNumber",
                          }}
                          data-test-id="phoneNumber"
                          placeholder="Phone number"
                          country={"de"}
                          value={values.phoneNumber}
                          onChange={(value, country, e, formattedValue) => {
                            setFieldValue("phoneNumber", value);
                            setFieldValue("countryCode", values.countryCode);
                          }}
                          enableSearch={true}
                          disableSearchIcon={false}
                          countryCodeEditable={true}
                          containerStyle={{ marginTop: "8px", width: "100%" }}
                        />
                      </StyledPhoneInput>
                    </FormControl>
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="email">Email</StyledFormLabelBrandProfile>
                      <StyledFieldBrandProfile
                        disabled
                        as={TextField}
                        data-test-id="email"
                        id="email"
                        name="email"
                        placeholder="Enter your email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.email) && touched.email}
                        InputLabelProps={{ shrink: true }}
                      />

                      {renderFormHelperText(errors.email, touched.email, this.state.emailError)}
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="taxIdentificationNumber">Tax Identification Number</StyledFormLabelBrandProfile>
                      <StyledFieldBrandProfile
                        as={TextField}
                        data-test-id="taxIdentificationNumber"
                        id="taxIdentificationNumber"
                        name="taxIdentificationNumber"
                        placeholder="XXXXXXXXXXXXX"
                        value={values.taxIdentificationNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.taxIdentificationNumber) && touched.taxIdentificationNumber}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />

                      {renderFormHelperText(errors.taxIdentificationNumber, touched.taxIdentificationNumber, null)}
                    </FormControl>

                    <FormControl fullWidth margin="normal">
                      <StyledFormLabelBrandProfile htmlFor="businessRegistrationProof">Business Registration Proof</StyledFormLabelBrandProfile>


                      <CustomUploadButton
                        data-test-id="uploadFile"
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<img src={this.state.uploadIcon} alt="Upload Icon" />}
                      >
                        Upload Document
                        <VisuallyHiddenInput
                          type="file"
                          onChange={(event) => {
                            const { files } = event.target;
                            if (files && files[0]) {
                              setFieldValue('file', files[0]);
                            }
                          }}
                        />
                      </CustomUploadButton>

                      {touched.file && errors.file && (
                        <Typography color="error" variant="body2" style={{ marginTop: '8px' }}>
                          {errors.file}
                        </Typography>
                      )}

                      
                      {values.file && (
                        <Typography variant="body2" style={{ marginTop: '8px' }}>
                          Uploaded file: {values.file.name}
                        </Typography>
                      )}
                    
                    </FormControl>






                    <StyledFieldButtonBrandProfile data-test-id="handleSignupData" variant="contained" fullWidth type="submit" sx={{ mt: 4 }}>
                      Continue
                    </StyledFieldButtonBrandProfile>


                  </Form>
                )}
              </Formik>
            </Grid>

          </Grid>
        </ThemeProvider>
      </MainWrapper>

    );
    // Customizable Area End
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
  "& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input": {
    paddingLeft: '8px !important', 
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    color:'#475569'
  },
  "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',
    color:'#475569'
  },
  "& .mainTitle": {
    color: '#000000',
    fontFamily: 'Inter',
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
    letterSpacing: '-0.015em',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '44px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
      lineHeight: '36px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      lineHeight: '32px',
    },
  },
  "& .bottomTitle": {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    color: "#1E293B",
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '24px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
      lineHeight: '22px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
      lineHeight: '20px',
    },
  },
  "& .loginButton": {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '28px',
    textAlign: 'left',
    color: "#9E52F6",
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  "& .optional-text": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: "#64748B",
    position: "relative",
    top: "-0.4em",
    marginLeft: '-3px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  "& .pass_check_title": {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '#1E293B',
    listStyle: "none",
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  "& .pass_check": {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: '#1E293B',
    listStyle: "none",
    padding: '3px 0px',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '10px',
    },
  },

  "& .checkBox_Main_Box": {
    marginTop: "16px",
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    [theme.breakpoints.down('md')]: {
      gap: '4px',
    },
    [theme.breakpoints.down('sm')]: {
      gap: '2px',
    },
    [theme.breakpoints.down('xs')]: {
      gap: '0px',
    },
  },
  "& .text": {
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    color: '#1E293B',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '12px',
    },
  },
  "& .text1": {
    color: '#1E293B',
    fontFamily: 'Inter',
    fontWeight: 700,
    lineHeight: '28px',
  },


}));
const StyledFieldBrandProfile = styled(Field)(({ theme }) => ({
  borderRadius: "12px",
  "& .MuiInputBase-root": {
    position: "relative",
    width: "100%",
    height: "56px",
    display: "flex",
    padding: "24px 0px",
    alignItems: "center",
    flexShrink: 0,
    background: theme.palette.common.white,
    marginTop: "4px",
    borderRadius: "8px",
    color: '#94A3B8',
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    fontFamily: "Inter",
    [theme.breakpoints.down('md')]: {
      fontSize: "16px",
      height: "48px",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      height: "40px",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      height: "32px",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px",
    color: '#475569',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '26px',

  }
}));
const StyledFieldButtonBrandProfile = styled(Button)(({ theme }) => ({
  width: '100%',
  height: '56px',
  padding: '16px',
  gap: '8px',
  borderRadius: '8px',
  backgroundColor: "#F1F5F9",
  color: "#64748B",
  fontFamily: 'Inter',
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '28px',
  textTransform: 'none',
  boxShadow: 'none',
  "&:hover": {
    backgroundColor: '#9E52F6 !important',
    color: "#FFFFFF",
  },
  "&:active": {
    backgroundColor: '#9E52F6 !important',
    color: "#FFFFFF",
  },
  "& .MuiTypography-root": {
    fontFamily: 'Inter !important',
    fontSize: '18px !important',
    fontWeight: '400 !important',
    lineHeight: '26px !important',
    textAlign: 'left !important',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '18px',
    height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '16px',
    height: '40px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '14px',
    height: '32px',
  },
}));
const StyledFormLabelBrandProfile = styled(FormLabel)(({ theme }) => ({
  color: '#334155',
  fontFamily: 'Inter',
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  letterSpacing: '-0.005em',
  textAlign: 'left',
  '&.Mui-focused': {
    color: '#334155',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '18px',
    lineHeight: '24px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '16px',
    lineHeight: '22px',
  },
}));
const StyledPhoneInput = styled('div')(({ theme }) => ({
  marginTop: '8px',
  width: '100%',

  '.react-tel-input .special-label': {
    display: 'none',
  },
  '.react-tel-input .form-control': {
    width: 'calc(100% - 46px)',
    marginLeft: '46px',
    padding: '17.5px 8px',
    borderRadius: '8px',
    color: '#475569'
  },
  '.react-tel-input .selected-flag .flag': {
    marginTop: '-10px',
    marginLeft: '-10px',
  },

  '& .phone-input': {
    position: 'relative',
    height: '56px',
    display: 'flex',
    padding: '16px 8px',
    alignItems: 'center',
    flexShrink: 0,
    borderRadius: '8px',
    background: theme.palette.common.white,
    color: '#475569',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    fontFamily: 'Inter',
    [theme.breakpoints.down('md')]: {
      fontSize: "16px",
      height: "48px",
      padding: '16px 8px',

    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "14px",
      height: "40px",
      padding: '16px 8px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      height: "32px",
      padding: '16px 8px',
    },
  },
}));
const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const CustomUploadButton = styled(Button)<ButtonProps>(({ theme }) => ({
  justifyContent: 'start',
  marginTop: '5px',
  width: '100%',
  height: '56px',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: "#F1F5F9",
  color: "#64748B",
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  textTransform: 'none',
  boxShadow: 'none',
  "&:hover": {
    backgroundColor: '#F1F5F9 !important',
    color: "#64748B",
    boxShadow: 'none',
  },
  [theme.breakpoints.down('md')]: {
    fontSize: '14px',
    height: '48px',
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '12px',
    height: '40px',
  },
  [theme.breakpoints.down('xs')]: {
    fontSize: '10px',
    height: '32px',
  },
}));



const StyledSelectField = (props: any) => {
  const { MenuProps, ...rest } = props;

  const selectStyles: React.CSSProperties = {
    borderRadius: '8px',
    height: '56px',
    color:'#343544 !important',
    [theme.breakpoints.down('md')]: {
         
          height: '48px',
        },
        [theme.breakpoints.down('sm')]: {
         
          height: '48px',
        },
        [theme.breakpoints.down('xs')]: {
       
          height: '48px',
        },
    
  };

  
  const inputStyles: React.CSSProperties = {
    color:'#343544 !important',
   
    textAlign: 'left',
    paddingLeft: '8px', 
  };

  return (
    <Select
      {...rest}
      style={selectStyles}
      InputProps={{
        
        style: inputStyles,
        classes: {
          input: 'custom-input', 
          
     
        },
      }}
      MenuProps={{
        ...MenuProps,
        PaperProps: {
          borderRadius: '8px',
          style: {
            minWidth: '150px',
            borderRadius: '8px',
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
            marginTop: '4px',
            border: '1px solid #F3EBFF',
          },
        },
        MenuListProps: {
          sx: {
            '& .MuiMenuItem-root': {
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              '&.Mui-selected': {
                backgroundColor: '#F3EBFF',
                '&:hover': {
                  backgroundColor: '#F1F5F9',
                },
              },
              '&:hover': {
                backgroundColor: '#F1F5F9',
              },
            },
          },
        },
      }}
    />
  );
};

// Customizable Area End

export default EmailAccountRegistrationBrandProfile;
