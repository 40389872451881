import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { logo, homeIconActive, homeIconInActive, subscriptionIconActive, subscriptionIconInActive, invoiceIconActive , invoiceIconInActive,logoutIcon, checkedIcon } from "./assets";
import  {getStorageData} from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentPage:number;
  logo:string;
  homeIconActive:string;
  homeIconInActive:string;
  subscriptionIconActive :string;
  subscriptionIconInActive :string;
  invoiceIconActive:string;
  invoiceIconInActive:string;
  logoutIcon:string;
  checkedIcon:string;
  mobileOpen:boolean;
  isMobile:number;
  clicked:boolean;
  plan:string;
  userSubscriptions:any
  activeItem: string,
  contactUs:boolean,
  subscriptions: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getSubscriptionListCallId:any;
  getUserSubscriptionsCallId:any
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      currentPage:1,
      logo:logo.default,
      homeIconActive:homeIconActive.default,
      homeIconInActive:homeIconInActive.default,
      subscriptionIconActive:subscriptionIconActive.default,
      subscriptionIconInActive:subscriptionIconInActive.default,
      invoiceIconActive:invoiceIconActive.default,
      invoiceIconInActive:invoiceIconInActive.default,
      logoutIcon:logoutIcon.default,
      checkedIcon:checkedIcon.default,
      mobileOpen:false,
      isMobile: window.innerWidth,
      clicked:false,
      plan:'annual',
      userSubscriptions:[],
      activeItem: 'Home',
      contactUs:false,
      subscriptions: []
    };
    this.handleResize = this.handleResize.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.getSubscriptionListCallId) {
          if (!responseJson.errors) {
            this.setState({
              subscriptions: responseJson.data,
            });
          }

        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    try {

      const token = await getStorageData("token");

      if (!token) {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message);
      }
      this.getUserSubscriptions();
      this.getSubscriptionsList();
    } catch (error) {

    }
  }
  

  async componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    this.setState({ isMobile: window.innerWidth });
  }

  handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    this.setState({ currentPage: value });
  };
  handleDrawerToggle = () => {
    this.setState((prevState: any) => ({ mobileOpen: !prevState.mobileOpen }));
  };
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  handleLogout=()=>{
    localStorage.removeItem("token");
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }
  navigateDrawer = (value:string)=>{
    this.setState({ activeItem: value, contactUs:false });
  }
  handleToggleChange = (event: any, newPlan: any) => {
    if (newPlan !== null) {
      this.setState({ plan: newPlan });
    }
  };
  handleGetStarted =(value:any)=>{


  }
  getSubscriptionsList = () => {
    const header = {
      "Content-Type": configJSON.subscriptionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubscriptionListCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSubscriptionListAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getSubscriptionAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getUserSubscriptions = () => {
    const header = {
      "Content-Type": configJSON.getUserSubscriptionsApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getUserSubscriptionsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserSubscriptionsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserSubscriptionsAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleCustomizePlan=()=>{
    this.setState({contactUs:true})
  }
  handleCloseContactUs=()=>{
    this.setState({contactUs:false})
  }
  // Customizable Area End
}
