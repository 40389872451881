import React from "react"; 
// Customizable Area Start 
import { TextField, FormControl, styled, Button, Grid, Box, Typography, ThemeProvider, IconButton, FormLabel,FormHelperText, Snackbar, Alert } from '@mui/material'; 
import { createTheme } from '@mui/material/styles';

import * as Yup from "yup"; import { Formik, Form, Field } from 'formik'; 
import Visibility from '@mui/icons-material/Visibility'; 
import VisibilityOff from '@mui/icons-material/VisibilityOff'; 
import { CheckIcon, CrossIcon } from "./assets";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Arial",
  },
});
const showPassword =(value:any)=>{
  return value ? "text" : "password"
  }
const showEyeButton =(value:any)=>{
    return value ? <Visibility /> : <VisibilityOff />
  }

const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], "Passwords must match")
      .required("Confirm Password is required"),
  });
  
   
const hasUppercase = (password: string) => /[A-Z]/.test(password);
const hasLowercase = (password: string) => /[a-z]/.test(password);
const hasNumber = (password: string) => /\d/.test(password);
const hasMinLength = (password: string) => password.length >= 8;
  
  
  const renderFormHelperText = (error?: string, touched?: boolean) => {
    if (error && touched) {
      return (
        <FormHelperText
          style={{
            fontFamily: "Inter",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "20px",
            textAlign: "left",
            color: "#DC2626",
            marginLeft: "0px",
            
          }}
        >
          {error}
        </FormHelperText>
      );
    }
    return null;
  };
// Customizable Area End

const configJSON = require("./config.js");

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import  {getStorageData} from "../../../framework/src/Utilities";

class NewPassword extends ForgotPasswordController { 
  static NewPassword: NewPassword;

constructor(props: Props) { 
  super(props); 
  // Customizable Area Start 
  // Customizable Area End 
}

// Customizable Area Start 
getToastMessage=()=> {
  const { showToastMessage } = this.state;
  if (typeof showToastMessage === 'string') {
    return showToastMessage;
  } else {
    return "Invalid message";
  }
}
async componentDidMount() {
  const email = await this.getEmail();
  this.setState({ email });
  }
getEmail = async () => {
    return await getStorageData("email");
  };
// Customizable Area End

render() { 
// Customizable Area Start 
  return (
    <ThemeProvider theme={theme}>
      <MainWrapper>
           <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ 
              padding: '16px',
              boxSizing: 'border-box',
            }}
          >
           <Snackbar
  open={this.state.showToast}
  autoHideDuration={6000}
  onClose={this.handleCloseToast}
  anchorOrigin={{ vertical: 'top', 
  horizontal: 'center' }}
  sx={{ top: '0px', justifyContent: 'center' }}
>
<Alert
data-test-id="alertdatatest"
  onClose={this.handleCloseToast} 
  sx={{ width: '100%' }}
  severity="error"
>
  
  {this.getToastMessage()}
</Alert>

</Snackbar>
{!this.state.SuccessMessage && (
            <Grid
              item
              xs={12}
              sm={8}
              md={6}
              lg={4}
              sx={{ 
                width:'100%',
                padding: "16px", 
                display: "flex", 
                flexDirection: "column", 
                alignItems: "center", 
                background: theme.palette.common.white, 
                borderRadius: "8px", 
              }}
            >
              
              <Box>
              <img 
                src={this.state.logo}
                alt="Logo" 
                style={{
                  marginTop:'60px',
                  marginBottom: '20px', 
                }} 
              /></Box>
    
              <Typography
                variant="h5"
                gutterBottom
                sx={{ 
                  marginTop:'20px',
                  color:'#000000', 
                  fontFamily: 'Inter',
                  fontSize: '30px',
                  fontWeight: 700,
                  lineHeight: '40px',
                  letterSpacing: '-0.005em',
                  textAlign: 'center',
                }}
              >
                New Password
              </Typography>
              <Typography 
                variant="body1" 
                sx={{ 
                  color:'#1E293B',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontWeight: 400,
                  lineHeight: '28px',
                  textAlign: 'center',
                  [theme.breakpoints.down(600)]:{
                    fontSize: '18px',

                  }
                }}
              >
                Enter a new password for <span>{this.state.email}</span>
              </Typography>
              <Formik
                data-test-id = "formik"
                initialValues={{ newPassword: "", confirmPassword: "" }}
                validationSchema={validationSchema}
                onSubmit={(values):any => {
                  const { newPassword } = values;
                  if (hasNumber(newPassword) && hasLowercase(newPassword) && hasUppercase(newPassword) && hasMinLength(newPassword)) {
                    this.handleSubmit(values)
                  } else {
                    this.setState({showToastMessage:'Password does not meet the criteria',showToast:true})
                  }
                
                }}
              >
                {({ handleChange, handleBlur, values, errors, touched, isValid, dirty }) => (
                  <Form className="form">
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabel htmlFor="newPassword">New Password</StyledFormLabel>
                      <StyledField
                        as={TextField}
                        id="newPassword"
                        data-test-id="newPassword"
                        name="newPassword"
                        type={showPassword(this.state.showPassword)}
                        placeholder="Enter your new password"
                        value={values.newPassword}
                        onChange={(e:any) => {
                          handleChange(e);
                          this.handlePasswordChange(e);
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.newPassword) && touched.newPassword}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-root': {
                            height: '56px',
                          },
                        }}
                        InputProps={{
                          endAdornment: (
                            <IconButton
            data-test-id="toggleNewPassword"
            aria-label="toggle password visibility"
            onClick={this.handleClickShowPassword}
            onMouseDown={this.handleMouseDownPassword}
          >
            
            {showEyeButton(this.state.showPassword)}
          </IconButton>
                          ),
                        }}
                      />
                      {renderFormHelperText(errors.newPassword, touched.newPassword)}
                    </FormControl>
                    
                    <FormControl fullWidth margin="normal">
                      <StyledFormLabel htmlFor="confirmPassword">Confirm Password</StyledFormLabel>
                      <StyledField
                        as={TextField}
                        id="confirmPassword"
                        data-test-id="confirmPassword"
                        name="confirmPassword"
                        type={showPassword(this.state.showConfirmPassword)}
                        placeholder="Confirm your new password"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={Boolean(errors.confirmPassword) && touched.confirmPassword}
                        InputLabelProps={{ shrink: true }}
                        sx={{
                          width: '100%',
                          '& .MuiInputBase-root': {
                            height: '56px',
                          },
                        }}
                        InputProps={{
                         
                          endAdornment: (
                            <IconButton
            data-test-id="toggleConfirmPassword"
            aria-label="toggle confirm password visibility"
            onClick={this.handleClickShowConfirmPassword}
            onMouseDown={this.handleMouseDownConfirmPassword}
          >
            {showEyeButton(this.state.showConfirmPassword)}
          </IconButton>
                          ),
                        }}
                      />
                      {renderFormHelperText(errors.confirmPassword, touched.confirmPassword)}
                    </FormControl>
    <Box>
                    {this.state.showValidationMessage && (
        <div>
          <p className="pass_check_title">Your Password must contain:</p>
          <ul style={{ display: "contents" }}>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasUppercase(this.state.password) ? CheckIcon : CrossIcon} At least one capital letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasLowercase(this.state.password) ? CheckIcon : CrossIcon} At least one lowercase letter
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasNumber(this.state.password) ? CheckIcon : CrossIcon} At least one number
            </li>
            <li className="pass_check" style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              {hasMinLength(this.state.password) ? CheckIcon : CrossIcon} Minimum character length is 8 characters
            </li>
          </ul>
        </div>
      )}
      </Box>
                    <StyledFieldButton
                      variant="contained"
                      data-test-id='handleSubmit'
                      fullWidth
                      type="submit"
                      sx={{
                        mt: 2,
                        backgroundColor: isValid && dirty ? '#9E52F6' : '#e0e0e0',
                        color: isValid && dirty ? '#FFFFFF' : '#000',
                        "&:hover": {
                          backgroundColor: isValid && dirty ? '#9E52F6' : '#e0e0e0',
                        },
                        textTransform: "none",
                        height: "48px",
                        borderRadius: "8px",
                        fontSize: "16px",
                        fontWeight: 400,
                      }}
                      disabled={!isValid || !dirty}
                    >
                      Set New Password
                    </StyledFieldButton>
    
                    <Grid container justifyContent="center" sx={{ mt: 2 }}>
                    
                      <Typography
                    variant="body2"
                    sx={{
                      mt: 3,
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 400,
                      lineHeight: '28px',
                      color:'#1E293B'
                    }}
                  >
                    Back to{' '}
                    <span onClick={()=>{this.navigateToSigninPage()}} data-test-id="navigateToLogin" className="loginButton" style={{ textDecoration: 'none', color: '#9E52F6',  textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '20px',
                      fontWeight: 700,
                      lineHeight: '28px', }}>
                          Login</span>
                  </Typography>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
)}
            {this.state.SuccessMessage && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: '#f9f9f9',
              padding: 2,
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                mb: 4,
              }}
            >
              
                <Box><img src={this.state.successLogo}></img></Box>
              
              <Typography variant="h5" sx={{  
                color:"#000000",
                fontFamily: 'Inter',
                fontSize: '30px',
                fontWeight: 700,
                lineHeight: '40px',
                letterSpacing: '-0.005em',
                textAlign: 'left', 
                marginTop:'36px'}}>
                Password Reset
              </Typography>
              <Box sx={{
                display: 'flex',
                flexWrap: 'wrap',
                paddingRight: '83px',
                paddingLeft: '83px'
              
              }}>
              <Typography variant="body1" sx={{ 
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '28px',
                textAlign: 'center',
                color :'#1E293B',
                marginTop:'24px',
                [theme.breakpoints.down('xs')]:{
                  fontSize: '16px',

                }
              }}>
                Your account is successfully recoverd,<br/> you can now browse the app with your new account.
              </Typography>
              </Box>
            </Box>
            <Button
              onClick={this.navigateToSigninPage}
              variant="contained"
              sx={{
                backgroundColor: '#A855F7',
                color: '#fff',
                width:'80%',
                height: '56px',
                borderRadius: '8px',
                '&:hover': {
                  backgroundColor: '#9333EA',
                },

                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: '28px',
                textAlign: 'left',
                textTransform:'none'
              }}
            >
              Continue
            </Button>
          </Box>
        )}

          </Grid>
          </MainWrapper>
        </ThemeProvider>

); 
// Customizable Area End

}
 }

// Customizable Area Start
const MainWrapper = styled(Box)(({ theme }) => ({
"& .form":{
    marginTop:'20px', width:'80%',
    [theme.breakpoints.down(600)]:{
      width:'100%'

    }
},
"& .pass_check_title": {
  fontFamily: 'Inter',fontSize: '20px',fontWeight: 400,
  lineHeight: '28px',color :'#1E293B',listStyle:"none",
  [theme.breakpoints.down('md')]: {fontSize: '18px'},
  [theme.breakpoints.down('sm')]: {fontSize: '16px'},
  [theme.breakpoints.down('xs')]: {fontSize: '14px'},
},
"& .pass_check": {
  fontFamily: 'Inter',fontSize: '16px',fontWeight: 400,
  lineHeight: '24px',textAlign: 'left',color :'#1E293B',
  listStyle:"none",padding: '3px 0px',
  [theme.breakpoints.down('md')]: {fontSize: '14px'},
  [theme.breakpoints.down('sm')]: {fontSize: '12px'},
  [theme.breakpoints.down('xs')]: {fontSize: '10px'},
},}));
const StyledField = styled(Field)(({ theme }) => ({
  borderRadius: "12px",
  "& .MuiInputBase-root": {position: "relative",width: "100%",height: "56px",
    display: "flex",padding: "24px 0px",alignItems: "center",
    flexShrink: 0,background: theme.palette.common.white,marginTop: "4px",
    borderRadius: "8px",color: '#94A3B8',fontSize: "18px",
    fontStyle: "normal",fontWeight: 400,lineHeight: "26px",
    fontFamily: "Inter",
    [theme.breakpoints.down('md')]: {fontSize: "16px",height: "48px"},
    [theme.breakpoints.down('sm')]: {fontSize: "14px",height: "40px"},
    [theme.breakpoints.down('xs')]: {fontSize: "12px",height: "32px"},
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px",color: '#475569',fontFamily: 'Inter',
    fontSize: '18px',fontWeight: 400,lineHeight: '26px',
  }
}));
const StyledFieldButton = styled(Button)(({ theme }) => ({
  width: '100%',height: '56px',padding: '16px',
  gap: '8px',borderRadius: '8px',backgroundColor: "#F1F5F9",
  color: "#64748B",fontFamily: 'Inter',fontSize: '20px',
  fontWeight: 700,lineHeight: '28px',textTransform: 'none',
  boxShadow: 'none',
  "&:hover": {backgroundColor: '#9E52F6 !important',color: "#FFFFFF",
  },
  "&:active": {backgroundColor: '#9E52F6 !important',color: "#FFFFFF",
  },
  "& .MuiTypography-root": {
    fontFamily: 'Inter !important',fontSize: '18px !important',fontWeight: '400 !important',
    lineHeight: '26px !important',textAlign: 'left !important',
  },
  [theme.breakpoints.down('md')]: {fontSize: '18px',height: '48px'},
  [theme.breakpoints.down('sm')]: {fontSize: '16px',height: '40px'},
  [theme.breakpoints.down('xs')]: {fontSize: '14px',height: '32px'},
}));
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  color: '#334155',fontFamily: 'Inter',fontSize: '24px',fontWeight: 700,
  lineHeight: '25px',letterSpacing: '-0.005em',textAlign: 'left',
  '&.Mui-focused': {color: '#334155'},
  [theme.breakpoints.down('md')]: {fontSize: '20px',lineHeight: '28px'},
  [theme.breakpoints.down('sm')]: {fontSize: '18px',lineHeight: '24px'},
  [theme.breakpoints.down('xs')]: {fontSize: '16px',lineHeight: '22px'},
}));

// Customizable Area End

export default NewPassword;