import React from "react";

// Customizable Area Start
import { StyleSheet } from "react-native";
import {
  TextField,
  FormControl,
  Button,
  Grid,
  Box,
  Typography,
  ThemeProvider,
  InputAdornment,
  IconButton,
  styled,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions,
  Divider
} from '@mui/material';
import { createTheme } from '@mui/material/styles';

import * as Yup from "yup";
import { Formik, Form, Field } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

const validationSchema = Yup.object({
  email: Yup.string()
  .required("Email is required")
  .test("no-spaces", "Please remove unnecessary space", (value) => {
    if (!value) return true;
    return !/\s/.test(value);
  })
  .email("Invalid email format"),
  password: Yup.string().required('Password is required')
});

function renderFormHelperText(error:any, touched:any, stateError:any) {
  const errorMessage = (error && touched && error) || stateError;

  if (errorMessage) {
      return (
          <FormHelperText
              style={{
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: "#DC2626",
                  marginLeft: "0px"
              }}
          >
              {errorMessage}
          </FormHelperText>
      );
  }
  return null;
}
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getErrorMessage = () => {
    return (
      this.state.errorMessage
    );
  };
  MainWrapper = styled(Box)(({ theme }) => ({
    "& .mainTitle": {
        color:'#000000',
        fontFamily: 'Inter',
        fontSize: '48px',
        fontWeight: 700,
        lineHeight: '56px',
        letterSpacing: '-0.015em',
        textAlign: 'center',
        marginBottom: '40px',
        [theme.breakpoints.up('md')]: {
          marginTop:'150px',
      },
        [theme.breakpoints.down('md')]: {
            fontSize: '36px',
            lineHeight: '44px',
            marginTop:'100px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '28px',
            lineHeight: '36px',
            marginTop:'50px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '24px',
            lineHeight: '32px',
            marginTop:'0px',
        },
    },
    "& .errorMessageBox":{
      color:'#DC2626',
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      textAlign: 'left',

    },
    "& .FormControl":{
      padding:'0px 0px 0px 0px'

    },
    "& .bottomTitle": {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        color:"#1E293B",
        marginTop:'23px',
        [theme.breakpoints.down('md')]: {
            fontSize: '18px',
            lineHeight: '24px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            lineHeight: '22px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            lineHeight: '20px',
        },
    },
    "& .loginButton":{
      marginTop:'18px'},

    "& .signupButton": 
    {fontFamily: 'Inter',fontSize: '20px',
        fontWeight: 700,lineHeight: '28px',
        textAlign: 'left',color:"#9E52F6",
        cursor: 'pointer',[theme.breakpoints.down('md')]: {
            fontSize: '18px',},
        [theme.breakpoints.down('sm')]: {fontSize: '16px'},
        [theme.breakpoints.down('xs')]: {fontSize: '14px'},
    },
    "& .optional-text": {
        fontFamily: 'Inter',fontSize: '16px',
        fontWeight: 400,lineHeight: '24px',
        textAlign: 'left',color:"#64748B",
        position: "relative",top: "-0.4em",
        marginLeft: '-3px',
        [theme.breakpoints.down('sm')]: {fontSize: '14px'},
        [theme.breakpoints.down('xs')]: {fontSize: '12px'}
    },
    "& .pass_check_title": {
        fontFamily: 'Inter',fontSize: '20px',
        fontWeight: 400,lineHeight: '28px',
        color :'#1E293B',listStyle:"none",
        [theme.breakpoints.down('md')]: {fontSize: '18px'},
        [theme.breakpoints.down('sm')]: {fontSize: '16px'},
        [theme.breakpoints.down('xs')]: {fontSize: '14px'}
      },
      "& .pass_check": {
        fontFamily: 'Inter',fontSize: '16px',
        fontWeight: 400,lineHeight: '24px',
        textAlign: 'left',color :'#1E293B',
        listStyle:"none",padding: '3px 0px',
        [theme.breakpoints.down('md')]: {fontSize: '14px'},
        [theme.breakpoints.down('sm')]: {fontSize: '12px'},
        [theme.breakpoints.down('xs')]: {fontSize: '10px'}},
    "& .react-tel-input .special-label": {display:'none'
    },
    "& .react-tel-input .form-control": {display: 'none',
        width: 'calc(100% - 46px)',marginLeft: '46px'},
    "& .react-tel-input .selected-flag .flag": {
      marginTop:"-10px",marginLeft:'-10px'
    },
    "& .checkBox_Main_Box": {display:'flex',
        flexDirection: 'row',justifyContent:"space-between",
        gap: '8px',marginTop: '12px',
        [theme.breakpoints.down('md')]: {gap: '4px'},
        [theme.breakpoints.down('sm')]: {gap: '2px'},
        [theme.breakpoints.down('xs')]: {gap: '0px'}
      },
    "& .checkBox_Remember_Box":{
      display:'flex',
      flexDirection: 'row',


    },
    "& .text": {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontWeight: 400,
        lineHeight: '28px',
        color:'#1E293B',
        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
        },
    },
    "& .text_Remember":{
    color:'#1E293B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
    textAlign: 'left',

    },
    "& .text_Forgot":{
      cursor:"pointer",
      color:'#000000',
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      textAlign: 'right',

    },
    "& .text1": {
        color:'#1E293B',
        fontFamily: 'Inter',
        fontWeight: 700,
        lineHeight: '28px',
    },
  }));
  StyledField = styled(Field)(({ theme }) => ({borderRadius: "12px","& .MuiInputBase-root": {
      position: "relative",width: "100%",
      height: "56px",display: "flex",
      padding: "24px 0px",alignItems: "center",
      flexShrink: 0,background: theme.palette.common.white,
      marginTop: "4px",borderRadius:"8px",
      color: '#94A3B8',fontSize: "18px",
      fontStyle: "normal",fontWeight: 400,
      lineHeight: "26px",fontFamily: "Inter",
      [theme.breakpoints.down('md')]: {fontSize: "16px",height: "48px"},
      [theme.breakpoints.down('sm')]: {fontSize: "14px",height: "40px"},
      [theme.breakpoints.down('xs')]: {fontSize: "12px",height: "32px"}
    },"& .MuiOutlinedInput-input": {
      padding:"8px",color:'#475569'
    }
   }));
  
  StyledFieldButton = styled(Button)(({ theme }) => ({
    height: '56px',
    padding: '16px',
    width: '100%',
    borderRadius: '8px',
    gap: '8px',
    fontFamily: 'Inter',
    backgroundColor:"#F1F5F9", 
    color:"#64748B",
    fontSize: '20px',
    lineHeight: '28px',
    fontWeight: 700,
    boxShadow: 'none',
    textTransform:'none',
    "&:hover": {
      color: "#FFFFFF", backgroundColor:'#9E52F6 !important',
      },
    "&:active": {
        backgroundColor:'#9E52F6 !important',color: "#FFFFFF", 
      },  
    
    "& .MuiTypography-root": {
      fontFamily: 'Inter !important',fontSize: '18px !important',
      fontWeight: '400 !important',lineHeight: '26px !important',
      textAlign: 'left !important',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',height: '48px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',height: '40px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',height: '32px',
    },
  }));
  
  StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    color: '#334155',fontFamily: 'Inter',
    fontSize: '24px',fontWeight: 700,
    lineHeight: '32px',letterSpacing: '-0.005em',
    textAlign: 'left',
     '&.Mui-focused': {
       color: '#334155',
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',lineHeight: '28px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px', lineHeight: '24px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '16px', lineHeight: '22px',
    },
  }));
  
  
  StyledFieldRadio = styled(RadioGroup)(({ theme }) => ({
       marginTop: '16px !important',
      '& .MuiFormControlLabel-root': {
       marginTop: '-10px !important',
      '& .MuiTypography-root': {
        marginTop: '3px',color: '#0F172A',
        fontFamily: 'Inter',fontSize: '18px',
        fontWeight: 400,lineHeight: '26px',
        textAlign: 'left',
    },
  },
     '& .MuiFormGroup-root': {
       marginTop: '16px !important',
    },
    '& .MuiRadio-root': {
      cursor: 'pointer !important',color: '#64748B !important',
       '&.Mui-checked': {
         color: '#9E52F6 !important',
      },
    },
    [theme.breakpoints.down('md')]: {
   '& .MuiTypography-root': {
         fontSize: '16px',
       },
     },
     [theme.breakpoints.down('sm')]: {
       '& .MuiTypography-root': {
         fontSize: '14px',
       },
     },
     [theme.breakpoints.down('xs')]: {
       '& .MuiTypography-root': {
          fontSize: '12px',
       },
      },
  }));
  
  
  StyledFieldCheckBox = styled(Checkbox)(({ theme }) => ({
     '&.MuiCheckbox-root': {
       '&:hover': {
         backgroundColor: 'transparent',
       },
     },
     '& .icon': {
      marginLeft: '1px',marginTop: '-6px',
      border: '1px solid #64748B',borderRadius: 6,
      width: 20,height: 20,
      backgroundColor: '#FFFFFF',
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '&.Mui-focusVisible': {
        outline: '2px auto rgba(19,124,189,.6)',outlineOffset: 2,
      },
       'input:hover ~ &': {
         backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',background: 'rgba(206,217,224,.5)',
      },
    },
    '& .checkedIcon': {
      backgroundColor: '#9E52F6',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',width: 20,
        height: 20,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
       },
      'input:hover ~ &': {
         backgroundColor: '#106ba3',
      },
     },
    '& .MuiFormControlLabel-root': {
       marginLeft: '0px !important',
      '& .MuiTypography-root': {
        fontSize: '16px',
      },
    },
    [theme.breakpoints.down('md')]: {
      '& .icon': {
        width: 18, height: 18,
      },
      '& .checkedIcon': {
        width: 18,height: 18,
        '&:before': {
          display: 'block',
          width: 18,height: 18,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
        },
      },
        '& .MuiFormControlLabel-root': {
          '& .MuiTypography-root': {
        fontSize: '14px',
        },
      },
    },
     [theme.breakpoints.down('sm')]: {
      '& .icon': {
        width: 16,
         height: 16,
      },
      '& .checkedIcon': {
          width: 16,
        height: 16,
        '&:before': {
          display: 'block',  width: 16,
          height: 16,
          backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
          content: '""',
          },
       },
      '& .MuiFormControlLabel-root': {
        '& .MuiTypography-root': {
           fontSize: '12px',
        },
      },
    },
    [theme.breakpoints.down('xs')]: {
      '& .icon': {
        width: 14, height: 14,
      },
      '& .checkedIcon': {
        height: 14,
        width: 14,
        '&:before': {
          display: 'block',height: 14,
          width: 14,
           backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
         },
       },
      '& .MuiFormControlLabel-root': {
        '& .MuiTypography-root': {fontSize: '10px',
        },
      },
    },
  }));
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <this.MainWrapper>
        <ThemeProvider theme={theme}>
          <Grid container spacing={0} height="100vh" overflow="none">

            <Grid 
            item xs={12} 
            sm={5} 
            className="grid_left">
              <Box className="main_box_left" 
              style={{ position: 'relative' }}>
                <img src={this.state.leftBannerImg} 
                alt="banner" style={{ width: '100%', height: '100vh'}} />
                <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <img src={this.state.logo} alt="logo" style={{ width: '100%', objectFit:'cover' }} />
                   </Box>
               </Box>
               </Grid>
            <Grid item
              xs={12} sm={7}
              sx={{
                height: '100%',display: 'block',
                overflowY: 'auto',
                paddingBottom: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingTop: { xs: '16px', sm: '32px', md: '48px', lg: '56px' },
                paddingRight: { xs: '16px', sm: '32px', md: '64px', lg: '120px' },
                paddingLeft: { xs: '16px', sm: '32px', md: '64px', lg: '137px' },
                flexDirection: 'column',justifyContent: 'center',
                alignItems: 'center',}}
            >
             
              <Typography className="mainTitle" gutterBottom>
              Welcome Back to Vatrina!
              </Typography>

              <Formik
                data-test-id="formik"
                enableReinitialize
                initialValues={{
                  email: this.state.email,
                  password: this.state.password,
                  rememberMe: false,
                }}
                validationSchema={validationSchema}
                onSubmit={(values) => {
                  this.handleLogin(values)
                }}
              >
                {({ handleChange, handleBlur, values, errors, touched }) => (
                  <Form>
                {Boolean(this.getErrorMessage()) && (
                <Box
                style={{
                  borderLeft: "4px solid red",
                  padding: "12px",
                  backgroundColor: "#FEE2E2",
                  marginBottom: "24px",
                  marginTop: '10px'
                }}
                >
                  <Typography className="errorMessageBox">
                    {this.state.errorMessage}
                  </Typography>
                </Box>
              )}
                      <FormControl fullWidth margin="normal">
                    <this.StyledFormLabel htmlFor="email">Email</this.StyledFormLabel>
                    <this.StyledField
                      as={TextField}
                      data-test-id="email"
                      id="email"
                      name="email"
                      placeholder="Enter your email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={Boolean(errors.email) && touched.email}
                      InputLabelProps={{ shrink: true }}
                    />
                     
                   {renderFormHelperText(errors.email, touched.email, null)}
                   </FormControl>
                    <FormControl fullWidth margin="normal" className="FormControl">
                      <this.StyledFormLabel htmlFor="password">
                        Password
                      </this.StyledFormLabel>
                      <this.StyledField
                        as={TextField}
                        data-test-id="password"
                        id="password"
                        name="password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        placeholder="Enter the password"
                        value={values.password}
                        onChange={(event: any) => {
                          handleChange(event)
                        }}
                        onBlur={handleBlur}
                        error={Boolean(errors.password) && touched.password}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                data-test-id="togglePassword"
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPasswordEye}
                                onMouseDown={this.handleMouseDownPassword}
                              >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}

                      />

                      {renderFormHelperText(errors.password, touched.password, null)}
                    </FormControl>


                  
                    <Box className="checkBox_Main_Box">
                    <Box className="checkBox_Remember_Box">
                      <Box>
                        <FormControlLabel
                          sx={{ display: 'flex', marginRight: '0px' }}
                          data-test-id="rememberCheckBox"
                          control={<this.StyledFieldCheckBox
                            as={Checkbox}
                            data-test-id="rememberCheckBoxInside"
                            name="rememberCheckBox"
                            className="root"
                            disableRipple
                            color="default"
                            checkedIcon={<span className={`icon checkedIcon`} />}
                            icon={<span className="icon" />}
                            inputProps={{
                              "aria-label": "decorative checkbox",
                            }}
                            onClick={() => {
                              this.setRememberMe(!this.state.checkedRememberMe);
                            }}
                            checked={this.state.checkedRememberMe}
                          />} label={undefined}


                        />
                      </Box>
                      <Box>
                        <span className="text_Remember">Remember me</span>
                      </Box>
                      </Box>
                      <Box sx={{display:'flex'}}>
                        <span data-test-id="navigateToForgotPasswordPage" onClick={() => {
                              this.navigateToForgotPasswordPage()
                            }} className="text_Forgot">Forgot your password?</span>
                      </Box>
                    </Box>

                    <Box className="Loginbutton">
                    <this.StyledFieldButton data-test-id="handleSigninData" variant="contained" fullWidth type="submit" sx={{ mt: 2 }}>
                      Login
                    </this.StyledFieldButton>
                    </Box>

                    <Typography className="bottomTitle" variant="body2" align="center" sx={{ mt: 2 }}>
                      Not a Member? <span onClick={this.navigateToSignup} data-test-id="navigateToSignup" className="signupButton">Sign up</span>
                    </Typography>
                  </Form>
                )}
              </Formik>
            </Grid>
           <Dialog
        open={this.state.open} onClose={this.handleClose}
        maxWidth="xs" fullWidth
         aria-labelledby="signup-dialog-title"
         sx={{
           '& .MuiDialog-paper': {
            borderRadius: 3, padding: 2,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',},
        }}>
        <DialogTitle 
          id="signup-dialog-title" sx={{ textAlign: 'center' ,  color:"#334155",
          fontWeight:700,fontSize:'20px',fontFamily:'Inter'}}>
          Are you here to shop or to sell?
        </DialogTitle>
          <Divider />
        <DialogContent 
        sx={{ py: 2 }}>
          <Typography 
          variant="body1" align="center" sx={{
          color:"#334155",fontWeight:400,
          fontSize:'15px',fontFamily:'Inter',}} gutterBottom>
            Join Vatrina as a...:</Typography>
          <Box display="flex" 
          justifyContent="center" gap={2} mt={2}>
            <Button
              data-test-id="navigateToUserSignup" variant={this.state.selectedOption === 'User' ? 'contained' : 'outlined'}
              onClick={() => this.navigateToUserSignup()} fullWidth
              sx={{fontWeight:700,
                fontSize:'15px',fontFamily:'Inter',
                borderRadius: '8px',border:'1px solid #CBD5E1',
                paddingY: 1,backgroundColor: '#F1F5F9',
                color: '#64748B',transition: '0.3s',
                boxShadow:'none',paddingBottom: '4px',
                textTransform:'none','&:hover': {
                  border:'1px solid #CBD5E1',backgroundColor: '#9E52F6',
                  color: '#FFFFFF',boxShadow:'none',
                  paddingBottom: '4px' },
               }}>
               Customer
              </Button>
            <Button
              data-test-id="navigateToBrandSignup" variant={this.state.selectedOption === 'Brand' ? 'contained' : 'outlined'}
              onClick={() => this.navigateToBrandSignup()} fullWidth
              sx={{
                fontWeight:700, fontSize:'15px',
                fontFamily:'Inter', borderRadius: '8px',
                border:'1px solid #CBD5E1', paddingY: 1,
                backgroundColor: '#F1F5F9', color: '#64748B',
                transition: '0.3s', boxShadow:'none',
                paddingBottom: '8px',textTransform:'none',
                '&:hover': {
                  border:'1px solid #CBD5E1',backgroundColor: '#9E52F6',
                  color: '#FFFFFF',boxShadow:'none',
                  paddingBottom: '8px'},
                }}
                > Business</Button>
              </Box>
            </DialogContent><Divider />
        <DialogActions sx={{ justifyContent: 'center',
         mt: 1 }}>
          <Button
            data-test-id="handleClose" onClick={this.handleClose}
            color="secondary" variant="outlined"
            sx={{
              fontWeight:700, fontSize:'15px',
              fontFamily:'Inter', borderRadius: '8px',
              border:'1px solid #CBD5E1',
              paddingY: 1, backgroundColor: 'transparent',
              color: '#9E52F6', transition: '0.3s',
              boxShadow:'none', paddingBottom: '8px',
              textTransform:'none',
              '&:hover': {
                border:'1px solid #CBD5E1',backgroundColor: '#F1F5F9',
                color: '#9E52F6',boxShadow:'none',paddingBottom: '8px'
              },}}>
            Go Back</Button>
        </DialogActions></Dialog>
             </Grid>
        </ThemeProvider>
      </this.MainWrapper>
      // Customizable Area End
    );
  }

}

const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
    width: "50%",
  },
  textFont: {
    fontFamily: "Helvetica"
  },
  formWrapper: {
    display: "flex",
    width: "100%",
    paddingTop: "20px" 
  },
  inputWrapper: {
    flex: 1,
    padding: "20px"
  },
  removeBackground: {
    backgroundColor: "transparent" 
  },
  checkBoxText: {
    fontSize: 18,
    color: "#6200EE",
    fontWeight: "bold",
  },
  buttonsWrapper: {
    flex: 1,
    padding: "10px",
  },
  OrWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "10px",
  }
});
